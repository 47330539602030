@import "../../styles/empCssLibs/variables";
@import "../../styles/common/CommonStyles.module";

.tableHead th {
  border-bottom: 1px solid $section-subheader-border-color;
}

.tableBody td {
  background-color: white;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: $secondary-text-color;
  border-bottom: 1px solid $section-table-row-border-color;
}

.detailsLink {
  font-size: 12px;
  font-weight: bold;
  color: $primary-text-bold-color;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.dealerAddress {
  display: flex;
  flex-direction: column;
}
