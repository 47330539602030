@import "../empCssLibs/variables.scss";

.subHeader {
  font-size: 13px;
  color: $tundora;
}

button.actionBtn {
  width: 90%;
  height: 58px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;

  font-size: 16px;
  font-weight: 500;
}

button.actionBtnLincolnWhite,
button.actionBtnLincolnWhite:hover {
  @extend .actionBtn;
  height: 50px;
  font-family: ProximaNovaRegular, sans-serif;
  color: #324047;
  background-color: #ffffff;
  background-image: none;
  border: solid 2px #324047;
  border-radius: 0px;
  text-transform: none;
  box-shadow: none;
}

button.actionBtnLincolnBlue,
button.actionBtnLincolnBlue:hover {
  @extend .actionBtnLincolnWhite;
  background-color: #324047;
  color: #ffffff;
}

button.closeBtn {
  @extend .actionBtn;
  background-image: linear-gradient(to bottom, $white, $white);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  color: $blue-zodiac;
}

div.closeLinkLincoln {
  border-bottom: solid 2px #f26147;
  font-family: ProximaNovaRegular, sans-serif;
  color: #324047;
  font-size: 16px;
  text-transform: none;
  cursor: pointer;
}

.pageTitle {
  font-size: 24px;
  color: #4d4d4d;
}

.errorText {
  font-size: 18px;
  color: #333333;
  padding-top: 36px;
  box-sizing: border-box;

  h2 {
    font-size: 20px;
    color: $primary-text-bold-color;
  }
}
