@import "../../styles/empCssLibs/variables-lincoln.scss";
@import "../../styles/empCssLibs/variables.scss";

.pagePadding {
  padding-left: 57px;
  padding-right: 43px;
}

.topDivider {
  border-bottom: 4px solid $cyan-blue;
  margin-bottom: 55px;
}

.dateDivider {
  border-bottom: 1px solid $aluminium;
  width: 15px;
  margin: 0 10px;
}

.bottomDivider {
  border-bottom: 1px solid $aluminium;
  width: 100%;
  padding-top: 42px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 39px;
  padding-bottom: 58px;
}

.title {
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  font-family: $lincoln-proxima-nova;
  padding-right: 30px;
  color: $primary-text-color;
}

.subTitle {
  font-size: 15px;
  letter-spacing: 1.94px;
  font-family: $lincoln-proxima-nova-bold;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 0.93;
  padding-right: 10px;
  padding-bottom: 10px;
  color: $primary-text-color;
}

.locationLabel {
  font-family: $lincoln-proxima-nova;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-weight: bolder;
  color: $tundora;
}

.locationLabelSub {
  @extend .locationLabel;
  padding-left: 10px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
}

.reportErrorLabel {
  @extend .locationLabel;
  padding-top: 10px;
  font-weight: normal;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.dateRange {
  font-family: $lincoln-proxima-nova;
  color: $cyan-blue;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
}

.dateRangeSub {
  @extend .dateRange;
  font-weight: normal;
  padding-left: 9px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
.resetLabel {
  font-size: 13px;
  letter-spacing: 2px;

  font-weight: bold;
  padding-left: 5px;
  padding-top: 2px;
  padding-right: 38px;
  color: $primary-text-color;
  cursor: pointer;
}

.subTitleBottom {
  @extend .subTitle;
  padding-top: 48px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: 1.88px;
}

.dropDown {
  display: flex;
  flex-direction: column;
}

.dropDownLabel {
  font-family: $lincoln-proxima-nova;
  padding-bottom: 5px;
  color: $primary-text-color;
  font-size: 14px;
  letter-spacing: normal;
  font-weight: normal;
}

.dropDownLabelDisabled {
  @extend .dropDownLabel;
  color: grey;
}

.fromLabel {
  color: $primary-text-color;
  font-size: 13px;
  letter-spacing: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.toLabel {
  @extend .fromLabel;
  padding-left: 10px;
}

.typeAndRegionWrapper {
  padding-top: 27px;
  padding-bottom: 60px;
}

.searchByLink {
  padding-top: 10px;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-weight: normal;
  font-size: 16px;
  cursor: pointer;
  color: $primary-text-color;
  margin-left: 0px;
  text-transform: none;
  border-bottom: 3px solid #f26147;
  letter-spacing: normal;
}

.searchInput input[type="text"] {
  width: 270px;
  color: $primary-text-bold-color;
  height: 50px;
  border: solid 1px $secondary-text-color;
  font-size: 14px;
  text-align: left;
  font-family: $lincoln-proxima-nova;
  line-height: 1.43;
  padding: 1px 10px;
  border-radius: 3px;
  letter-spacing: Normal;
  background-color: $white;
}
.searchInput > div:before {
  border-bottom: none;
  transition: none;
}
.searchInput > div:after {
  border-bottom: none;
  transition: none;
}

input {
  width: 130px;
  color: $primary-text-bold-color;
  height: 22px;
  border: solid 1px $secondary-text-color;
  font-size: 14px;
  text-align: left;

  line-height: 1.43;
  padding: 1px 10px;
  border-radius: 3px;
  letter-spacing: 1px;
  background-color: $white;
}

.error {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  padding-left: 39px;
  color: red;
}

.dateRangeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 13px;
}

.dropDownDateWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.generateReport {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 84px;
  padding-top: 16px;
}

.reset {
  transform: rotate(-75deg);
  cursor: pointer;
}

.buttonContainer {
  width: 140px;
  height: 50px;
  display: inline-flex;
  align-items: center;
}

.downloadButtonContainer {
  @extend .buttonContainer;
  width: 158px;
  float: right;
  margin-right: 27px;
}

button.actionBtn {
  width: 90%;
  height: 50px;
  font-family: $lincoln-proxima-nova-semi-bold;
  box-shadow: none;
  color: $primary-bg-color;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 0;
  font-size: 16px;
  cursor: pointer;
  text-transform: none;
  background-color: $primary-bar-bg-color;
}

button.actionBtn:hover {
  background-color: $oxford-blue;
}

button.actionBtn:disabled {
  @extend .actionBtn;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}

.datePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calImgFrom {
  position: absolute;
  bottom: 8px;
  right: 16px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  cursor: pointer;
}

.calImgTo {
  position: absolute;
  bottom: 8px;
  right: 7px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  cursor: pointer;
}

.toFromDatePickerWrapper {
  padding-bottom: 19px;
}

.datePickerInputIcon {
  position: relative;
  svg {
    position: absolute;
    bottom: 8px;
    right: 7px;
    pointer-events: none;
    cursor: pointer;
  }
}

.datePickerInputIcon input[type="text"] {
  height: 24px;
  width: 135px;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $primary-text-color;
}

.orPadding {
  padding-right: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $primary-text-color;
}

.modelFormControl {
  width: 85%;
}

.dropDownsWrapper {
  display: flex;
}

.dropdownAutoDate {
  width: 220px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dropdownDiv {
  width: 250px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $cyan-blue;
}

.dropDownTextSmall {
  @extend .dropDownText;
  font-size: 16px;
}

.blueBar {
  height: 50px;
  background: $cyan-blue;
  color: $cyan-blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 6%;
  padding-right: 6%;
}

.blueBar .flexContainer {
  height: 50px;
}
