@import "variables";

/** =============================================================================================
**  Page Paddings==============================================================================
**  ==========================================================================================**/
.pagePaddingLeft {
  padding-left: $horizontal-spacing;
}

.pagePaddingRight {
  padding-right: $horizontal-spacing;
}

.pagePaddingTop {
  padding-top: $vertical-spacing;
}

.pagePaddingBottom {
  padding-bottom: $vertical-spacing;
}

.pagePadding {
  @extend .pagePaddingLeft;
  @extend .pagePaddingRight;
  @extend .pagePaddingTop;
  @extend .pagePaddingBottom;
}

/** =============================================================================================
**  Utility Classes==============================================================================
**  ==========================================================================================**/
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.center {
  text-align: center;
}
