@import "../../../styles/empCssLibs/variables-lincoln.scss";
@import "./NAOrderDetailsNewVersion.module";

.backButtonDiv {
  margin: 40px 5% 10px 4%;
}

.backButton {
  background-color: $white;
}

.backToOrderText {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
  letter-spacing: 1.5px;
}

.newOrdersDiv {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
}

.componentContainer {
  width: 90%;
  margin: 5px auto 20px auto;
  box-sizing: border-box;
}

.expansionBox {
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.headerBar {
  color: $lincoln-navy-blue;
  font-family: ProximaNovaRegular, sans-serif;
}

.headerBarNoIdent {
  color: $lincoln-navy-blue;
  font-family: ProximaNovaRegular, sans-serif;
}

.regularHeader {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
}

.regularHeaderValue {
  @extend .regularHeader;
  padding-left: 0;
}

.boldHeader {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: normal;
  line-height: 1.59;
  text-transform: uppercase;
}

.header {
  color: $lincoln-navy-blue;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  letter-spacing: 1.5px;
  font-weight: 600;
  line-height: 1.17;
  text-transform: uppercase;
}

.customerInfoGrid {
  padding: 0 4% 0 4%;
}

.bottomBorder {
  border-bottom: 1px solid $lincoln-navy-blue;
  margin-bottom: 1%;
}

.label {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
}

.subHeaderText {
  font-family: $lincoln-proxima-nova;
  color: $lincoln-navy-blue;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: 2px;
  padding: 25px 0px;
}

.headerText {
  color: $lincoln-navy-blue;
  font-family: ProximaNovaRegular, sans-serif;
}

.subHeaderSpan {
  @extend .subHeaderText;
  padding-bottom: 14px;
  width: 320px;
}

.subHeaderRow {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.itemHeaderText {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: 1px;
  color: $lincoln-navy-blue;
  padding-bottom: 2px;
}

.itemValueText {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  color: $lincoln-navy-blue;
  padding-bottom: 20px;
  text-transform: uppercase;
  overflow-wrap: break-word;
}

.itemValueCapitalizeText {
  @extend .itemValueText;
  text-transform: none;
}

.customerInfoContainer {
  padding-bottom: 15px;
}

.customerInfoText {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
}

.customerInfoTextBold {
  @extend .customerInfoText;
  font-weight: 600;
}

.itemValueEmailText {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 2.08;
  letter-spacing: 2px;
  color: $secondary-text-color;
  text-decoration: underline;
}

.customerInfoBar {
  padding: 0rem 1rem;
  font-weight: 100;
  font-size: 14px;
}

.customerInfoDetails {
  @extend .customerInfoBar;
  border-right: 2px solid grey;
}

.pricingSummaryExpansion {
  @extend .headerBar;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  line-height: 2.5;
  text-transform: uppercase;
}

.featuresExpansion {
  @extend .pricingSummaryExpansion;
  width: auto;
}

.featuresExpansionNoIndent {
  color: $primary-text-bold-color;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  line-height: 2.5;
  text-transform: uppercase;
  width: auto;
}

.pricingSummaryExpansionSubheader {
  width: 100%;
  padding: 0 4%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 2.5;
  text-transform: uppercase;
}

.dealerPrice {
  display: flex;
  justify-content: flex-end;
  padding-left: 2%;
}

.priceDetailsIndent {
  padding-left: 4%;
}

.priceDetails {
  text-transform: uppercase;
}

.lightHeader {
  color: $primary-text-bold-color;
  text-transform: uppercase;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  letter-spacing: 2.5px;
  line-height: 3.5;
}

.darkHeader {
  @extend .lightHeader;
  background: $smoky-gray;
}

.lightCaseSensitiveHeader {
  @extend .lightHeader;
  text-transform: none;
  font-family: ProximaNovaRegular, sans-serif;
  color: $tundora;
}

.darkCaseSensitiveHeader {
  @extend .lightHeader;
  text-transform: none;
  font-family: ProximaNovaRegular, sans-serif;
  color: $tundora;
  background: $smoky-gray;
}

.darkCaseSensitiveBoldHeader {
  @extend .header;
  background: $smoky-gray;
  line-height: 3.5;
}

.rightAlign {
  text-align: right;
}

.leftAlign {
  text-align: left;
}

.leftSpacing {
  padding-left: 4%;
}

.rightIndent {
  padding: 0 8% 0 6px;
}

.specsContainer {
  width: 100%;
}

.cancelDiv,
.refundDiv {
  text-align: right;
}

.cancelDiv {
  display: flex;
  justify-content: right;
}

.cancelButton,
.refundButton {
  width: 50%;
  min-height: 25px;
  height: auto;
  background-color: $primary-text-bold-color;
  cursor: pointer;
  color: $white;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1px;
  border-radius: 20px;
  border: solid 2px $primary-text-bold-color;
}

.blueButton {
  @extend .cancelButton;
  background-color: #164069;
  white-space: nowrap;
  width: auto;
  border: none;
  padding: 3px 25px;
}

.acceptOrderButton {
  @extend .cancelButton;
  background-color: #164069;
  white-space: nowrap;
  width: 35% !important;
  border: none;
}

.rejectOrderButton {
  @extend .acceptOrderButton;
  background-color: $white;
  color: $lincoln-navy-blue;
  border: solid 2px #164069;
}

.cancelButton:focus,
.refundButton:focus {
  outline: none;
}

.cancelButton:disabled,
.refundButton:disabled,
.updateBtn:disabled {
  color: gray;
  cursor: auto;
}

.blueButton:disabled {
  @extend .cancelButton;
  background-color: lightgray;
}

.refundBtn:disabled {
  visibility: hidden;
}

.darkIndentDetails {
  @extend .darkHeader;
}

.lightIndentDetails {
  @extend .lightHeader;
}

.darkIndentDetails .leftIndent {
  padding-left: 12%;
}

.infoIcon {
  background-color: $white;
  border: 1px solid $soft-blue;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  font-size: 12px;
  color: $soft-blue;
  font-weight: bold;
  text-transform: lowercase;
}

.leftIndent button {
  padding: 0;
  margin-left: 10px;
  min-width: 20px;
}

.tooltipHeader {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
}

.tooltipBody {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $lincoln-navy-blue;
  margin-top: 12px;
}

.closeButton {
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/close_Cancel.svg");
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 12px;
}

.indent {
  padding-left: 4%;
  padding-right: 4%;
}

.baseIndent {
  padding-left: 2%;
}

.mediumIndent {
  padding-left: 4%;
}

.rightIndent {
  padding-right: 8%;
}

.smallRightIndent {
  padding-right: 2.2%;
}

.largeIndent {
  padding-left: 6%;
}

.printText {
  font-family: ProximaNovaRegular, sans-serif;
  color: $lincoln-navy-blue;
  letter-spacing: 2.17px;
  font-size: 12px;
}

.printIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.detailsContainer {
  @extend .newOrdersDiv;
  padding: 0 5%;
}

.leftPadding {
  padding-left: 5%;
}

.rightPadding {
  padding-right: 5%;
  float: right;
}

@media print {
  .printIcon {
    visibility: hidden;
  }
  .printText {
    visibility: hidden;
  }
}

.sectionHeader {
  margin: 25px auto;
  width: 90%;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.chargingHeader {
  padding: 0 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chargingIconDiv {
  display: flex;
}

.chargingRow {
  padding: 0 calc(6% + 25px) 0 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updateBtn,
.refundButtonSmall,
.refundBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 9px;
  text-decoration: underline;
  font-weight: 300;
  line-height: 2.08;
  letter-spacing: 2px;
  color: $primary-text-bold-color;
  text-transform: uppercase;
  padding-left: 10px;
}

.refundButtonSmall:disabled {
  color: gray;
  cursor: auto;
}

.refundDepositNA {
  @extend .refundButtonSmall;
  letter-spacing: 1px;
}

.disabledRefundDepositNA {
  @extend .refundButtonSmall;
  letter-spacing: 1px;
  color: $dark-grey;
  cursor: auto;
}

.exitButton {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.bodyContent {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $lincoln-navy-blue;
  margin-bottom: 15px;
}

.acceptPurchaseNAButton {
  @extend .cancelButton;
  background-color: $lincoln-navy-blue;
  text-decoration: none;
  color: white;
  border-radius: 0;
}

.cancelPurchaseNAButton {
  @extend .cancelButton;
  background-color: white;
  color: $primary-text-bold-color;
  width: auto;
}

.acceptPurchaseNAButton:disabled,
.cancelPurchaseNAButton:disabled {
  background-color: $gray90;
  border: solid 1px $gray90;
}

.smallHeaderMargin {
  margin-top: 5px;
}

.headerMargin {
  margin-top: 10px;
}

.errorText {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.94;
  letter-spacing: 2.25px;
  color: $button-primary-bg-color;
  padding-top: 36px;
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  text-transform: uppercase;

  h2 {
    font-size: 20px;
    font-family: ProximaNovaRegular, sans-serif;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $primary-text-bold-color;
  }
}

@media print {
  .noPrint {
    display: none !important;
  }
}

.itemHeaderSpan {
  padding-top: 10px;
}

.windowStickerLink {
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: ProximaNovaRegularRegular, sans-serif;
  font-size: 13px;
  text-decoration: underline;
  text-transform: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: $lincoln-navy-blue;
}

.floatingActionButtonDiv {
  width: 79%;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: 1;
  margin-left: 103px;
}

.floatingDiv {
  width: 79%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  z-index: 2;
  margin-top: 900px;
}

button.floatingActionButton,
button.floatingActionButton:hover {
  background-color: $lincoln-oxford-blue;
  color: $primary-bg-color;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 3px;
  text-align: center;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  border-radius: 20px;
}

.searchText {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 2.2px;
  color: #ffffff;
  padding-right: 10px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchWrapper button {
  border: none;
}

.searchWrapper button:focus {
  outline: none;
}

.searchIcon {
  cursor: pointer;
  width: 29px;
  height: 29px;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/search.svg");
}

.closeIcon {
  cursor: pointer;
  padding: 0;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/close_Cancel.svg");
}

.inputField {
  width: 240px;
  height: 22px;
  background-color: #f6f6f6;
}

.inputField::-ms-clear {
  display: none;
}

.searchWrapper input[type="text"] {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 10px;
  letter-spacing: 0.71px;
  color: $primary-text-bold-color;
  padding: 6px;
  text-align: left;
}

.searchButton {
  position: relative;
  right: 40px;
  border: none;
  background: transparent;
}

.appointmentFulfilled {
  display: flex;
}

.blueBar {
  padding: 0 6%;
  display: flex;
  align-items: center;
  height: 50px;
  background-image: none;
  background-color: $lincoln-navy-blue;
}

.acceptRejectDiv {
  display: flex;
  align-items: flex-end;
}
