@import "../../styles/empCssLibs/variables";

.pageHeader {
  height: 60px;
  text-align: center;
  padding-top: 11px;
}

.reportingHeader {
  text-align: left;
  height: 35px;
  font-size: 26px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: 3.25px;
  color: $primary-text-color;
}

.margin {
  margin-left: 95px;
}

.flexWithSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.border-bottom {
  border-bottom: solid 0.5px $section-table-row-border-color;
}

.headerContainer {
  width: 100%;
  display: flex;
  color: $button-primary-bg-color;
  height: 35px;
  font-size: 10px;
  line-height: 3.5;
  letter-spacing: 1.67px;
  padding-bottom: 7px;
  border-bottom: solid 0.5px $secondary-border-color;
}

.rowDiv {
  display: flex;
  width: 100%;
  @extend .border-bottom;
}

.end {
  justify-content: flex-end;
}

.colValues {
  height: 20px;
  font-size: 12px;
  line-height: 2.25;
  letter-spacing: 0.86px;
  color: $secondary-text-color;
  padding-top: 5px;
  padding-bottom: 12px;
}

.link {
  font-size: 12px;
  font-weight: 500;
  line-height: 3;
  letter-spacing: 2.25px;
  text-align: right;
  color: $button-primary-bg-color;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
}

a.link:hover {
  cursor: pointer;
}

.dataContainer {
  padding-top: 26px;
}
