.sortUpFilled {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/lincoln-arrow-up.svg');
  display: block;
  letter-spacing: 0px;
}

.sortDown {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/lincoln-arrow-down.svg');
  display: block;
  letter-spacing: 0px;
}

.sortDownFilled {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/lincoln-arrow-down.svg');
  display: block;
  letter-spacing: 0px;
}