@import "../../../styles/empCssLibs/variables.scss";

.pagePadding {
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0 56px;
  }
}

.title {
  padding: 10px 0;

  font-size: 12px;
  height: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
}

.percentage {
  @extend .alignLeft;
  height: 31px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  font-weight: 600;

  color: $primary-text-color;
}

.headerPercentage {
  @extend .percentage;
  color: $header-bar-text-color;
}

.currency {
  height: 31px;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  font-weight: 600;

  color: $primary-text-color;
}

.headerCurrency {
  @extend .currency;
  color: $header-bar-text-color;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.namePlateHeader {
  padding: 2% 6% 5px 6%;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  text-align: right;
  color: $primary-text-bold-color;
  line-height: 1.6;
}

.settingsContainer {
  background-color: $secondary-bar-bg-color;
  height: 150px;
}

.categoryHeader {
  @extend .namePlateHeader;
  border-bottom: 1px solid $pale-gray;
}

.categoryRow {
  padding: 0 6%;
  letter-spacing: 1.5px;
  line-height: 1.5;
  border-bottom: 1px solid $pale-gray;
}

.namePlateTextDiv {
  height: auto;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  color: $header-bar-text-color;
  text-align: right;
  display: flex;
  align-items: center;
  background-color: $blue-zodiac;
  padding: 0 6%;
}

.brand {
  display: flex;
  align-items: center;
}

.brandName {
  font-size: 10px;

  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: $primary-text-bold-color;
}

.brandValue {
  font-size: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: $secondary-text-color;
}

.brandText {
  padding-right: 75px;
}

.textBox {
  height: auto;
  margin: 5px 0;
}

.textBox input {
  width: 50px;
}

.textBox p {
  margin-bottom: 5px;
}

.laborRateRow {
  @extend .brand;
  align-items: center;
  justify-content: center;
}

.greenButton {
  border: none;
  border-radius: 0;
  padding: 3% 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: 2.3px;
    text-align: center;
    width: 300px;
    border-radius: 0;
    box-shadow: none;
    min-height: 48px;
    height: auto;
    border: none;
    background-color: green;
    background-image: linear-gradient(to bottom, #00ad46, #00913b);
    color: $white;
  }

  button:disabled {
    background-color: rgba(128, 128, 128, 0.25);
    color: $pale-gray;
    background-image: linear-gradient(to bottom, $gray90, $gray90);
  }
}

.overridesDialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 6%;
  text-align: center;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

button.brandConfirm {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-text-bold-color,
    $primary-text-bold-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.brandConfirm:hover {
  cursor: pointer;
}

button.brandConfirm p {
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

button.customiseToBtn {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-bg-color,
    $primary-bg-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.customiseToBtn:hover {
  cursor: pointer;
}

button.customiseToBtn p {
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
}

.dropdownsWrapper {
  display: flex;
}

.dropdownDiv {
  width: 250px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.subTitle {
  padding: 10px 0 5px 0;
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
}

.headerBlueRow {
  background-color: $blue-zodiac;
}

.expansionBar {
  width: 100%;
  font-size: 11px;

  letter-spacing: 2.25px;
  padding: 0 6%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
}

.cancel {
  padding: 10px 5px 0 10px;
  background: transparent;
  justify-content: flex-end;
  display: flex;
}

.cancel button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.buttonsDialog {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 40px;
}

button.applyButton {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-text-bold-color,
    $primary-text-bold-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.applyButton:hover {
  cursor: pointer;
}

button.applyButton p {
  margin: 0;
  padding: 0 25px;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

button.cancelButtonDialog {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-bg-color,
    $primary-bg-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.cancelButtonDialog:hover {
  cursor: pointer;
}

button.cancelButtonDialog p {
  margin: 0;
  letter-spacing: 1.5px;
  padding: 0 25px;
  font-size: 14px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
}

.notFound {
  padding-left: 6%;
  padding-top: 1%;
  text-align: left;
}

.label {
  padding-top: 6px;
}

.alignSortIcon {
  display: flex;
  align-items: flex-end;
  //justify-content: flex-start;
}

.typeHeader {
  @extend .alignLeft;
  @extend .alignSortIcon;
}

.markupTypeLabel {
  @extend .label;
  @extend .alignSortIcon;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialogTitle {
  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 2px;
  color: $secondary-green;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.overridesApplied {
  @extend .dialogTitle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
}

.sortingIcon {
  margin-bottom: 2px;
}
