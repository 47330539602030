@import "../../../styles/empCssLibs/variables";
@import "../../../styles/common/CommonStyles.module";

.dealerName {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: $blue-zodiac;
  padding-bottom: 9px;
}

.dealerInfo {
  @extend .dealerName;
  font-weight: normal;
  font-size: 9px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  font-size: 13px;
  letter-spacing: 1px;
  font-weight: normal;
  color: $tundora;
  padding-bottom: 34px;
}

.buttonContainer {
  width: 140px;
  height: 50px;
  display: inline-flex;
  align-items: center;
}

.downloadButtonContainer {
  @extend .buttonContainer;
  width: 158px;
  float: right;
  margin-right: 27px;
}

.table {
  border-top: 3px solid $blue-zodiac;
  line-height: 1.17;
}

.tablePadding {
  padding-bottom: 40px;
}
