@import "../../styles/empCssLibs/variables";

label {
  display: inline;
}

.checkbox {
  display: none;
}

.checkboxHidden {
  visibility: hidden;
}

.checkbox + label {
  background-color: #ffffff;
  border: 1px solid $primary-text-bold-color;
  display: inline-block;
  position: relative;
  content: " ";
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.checkbox:checked + label {
  background-color: $primary-bg-color;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #000000;
}

.bold:checked + label {
  border: 1px solid $primary-text-bold-color;
}

.checkbox:checked + label:after {
  content: "\2713";
  font-size: 20px;
  position: absolute;
  /*top: 0px;*/
  left: 4px;
  bottom: -1px;
  color: $secondary-border-color;
}

.bold:checked + label:after {
  color: $primary-text-bold-color;
}

.checkbox:checked:disabled + label {
  background-color: $alto;
  border: 1px solid $dusty-gray;
  cursor: default;
}

.checkbox:disabled + label {
  background-color: $alto;
  border: 1px solid $dusty-gray;
  cursor: default;
}

.faded:checked:disabled + label {
  background-color: $alabaster;
}

.faded:disabled + label {
  background-color: $alabaster;
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
}

.checkboxWrapper {
  height: 24px;
}

.label {
  margin-left: 10px;

  font-size: 16px;
  color: $tundora;
}

.labelList {
  font-size: 16px;
  color: $tundora;
}

.labelList li {
  margin: 10px 0;
  list-style: disc;
}

.boldLabel {
  font-size: 14px;
  color: $primary-text-color;
  font-weight: 500;
  padding: 5px 10px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.disabledLabel {
  @extend .boldLabel;
  color: $dusty-gray;
}

.cancelLabel {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  padding: 3px 0 0 8px;
}
