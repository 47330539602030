@import "../../../../styles/empCssLibs/variables";

.pageContainer {
  padding: 0 25px;

  @media screen and (min-width: 1024px) {
    padding: 0 50px;
  }

  .subHeader {
    font-size: 13px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    padding-top: 48px;
    color: $tundora;
  }

  .gridHeader {
    padding-bottom: 8px;
    border-bottom: solid 4px $blue-zodiac;

    .headerText {
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $blue-zodiac;
    }

    .typeHeader {
      @extend .headerText;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .sorting {

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}


.gridBody {
  padding: 10px 0;
  border-bottom: solid 1px $dusty-gray;
  align-items: center;


  .colType {
    font-size: 16px;
    font-weight: 500;
    color: $blue-zodiac;
    margin: 0;
    line-height: 1;
  }
}


