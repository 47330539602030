@import '../../styles/empCssLibs/variables-lincoln.scss';

.infoBar {
  font-family: $lincoln-proxima-nova;
  background-color: $white;
  padding: 12px 25px;
  border-bottom: 1px solid #ececec;

  @media screen and (min-width: 1024px) {
    padding: 12px 50px;
  }

  .brandLogo {
    padding: 0 10px 0 0;
  }

  .logoutContainer {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .dealerBox {
     cursor: pointer;
      padding-left: 16px;
      .menuAlign {
        display: flex;
        align-items: center;
      }

      .adminMenu {
        text-align: right;
      }

      .dealerDetails {

        .detailsMeta {
          flex: 1 0 auto;
        }

        .detailsLink {
          flex: 0 0 auto;
        }

        a {
          font-family: $lincoln-proxima-nova-semi-bold;
          font-size: 14px;
          text-transform: uppercase;
          color: $primary-text-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .userName {
          font-family: $lincoln-proxima-nova-semi-bold;
          font-size: 12px;
          color: $primary-text-color;
          text-align: right;

          .userNameBold {
            font-family: $lincoln-proxima-nova-semi-bold;
          }
        }

        .dealerName, .dealerType, .dealerCommonId {
          font-family: $lincoln-proxima-nova-semi-bold;
          font-size: 12px;
          color: $primary-text-color;
          text-align: right;
          margin-bottom: 3px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .dealerType, .dealerCommonId {
          color: #6b767b;
          letter-spacing: 0.5px;
        }
      }
    }

    .logoutBox {
      box-sizing: border-box;
      flex: 0 0 100%;
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: 768px) {
        padding: 0 5px;
        justify-content: flex-end;
        flex: 0 0 auto;
      }

      .logoutFont {
        font-family: $lincoln-proxima-nova-semi-bold;
        font-size: 14px;
        text-transform: uppercase;
        color: $primary-text-color;
        text-decoration: none;
        line-height: 1;
        letter-spacing: 1px;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;
        margin-bottom: -3px;
        transition: border-bottom-color 0.2s ease;

        &:hover {
          cursor: pointer;
          border-bottom: 2px solid $primary-border-color;
        }
      }

      .brandSwitcher {
        margin-right: 15px;
      }
    }
  }
}

header.globalNavBar {
  background-color: rgb(239,239,239);
  box-shadow: none;
  top: 0;
  left: auto;
  right: auto;
  max-width: 1440px;
  min-height: 70px;
  height: auto;
  padding-right: 0 !important;
  width: 100%;

  .navContainer {
    padding: 12px 25px;
    overflow-x: scroll;

    @media screen and (min-width: 768px) {
      padding: 12px 25px;
      overflow-x: initial;
    }

    @media screen and (min-width: 1024px) {
      padding: 12px 50px;
    }

    .navItems {
      display: flex;
      flex-wrap: nowrap;
      justify-content:flex-start;
      align-items: center;
      flex: 0 0 auto;

      a {
        font-family: $lincoln-proxima-nova-semi-bold;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1;
        color: $primary-text-color;
        text-decoration: none;
        text-transform: uppercase;
        margin: 0 25px -3px 0;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;
        transition: border-bottom-color 0.2s ease;

        &:hover {
          border-bottom: 2px solid $primary-border-color;
        }

        &.active {
          border-bottom: 2px solid $primary-border-color;
        }
      }

      .portalMenu {
        font-family: $lincoln-proxima-nova-semi-bold;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 1;
        color: $primary-text-color;
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin-bottom: -3px;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;
        transition: border-bottom-color 0.2s ease;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.dealerMenuSection.dealerMenuRight {
  padding-right: 40px !important;
}

.dealerMenuSection .activeDealerMenuItem {
  color: $primary-text-color;
}

.menuHR {
  margin-left: 40px;
}

.dialogTitle {
  font-family: $lincoln-proxima-nova;
  color: $cyan-blue;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 8px;
}

.dialogTitleBold {
  padding-top: 2px;
  font-family: $lincoln-proxima-nova-semi-bold;
  color: $cyan-blue;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-left: 8px;
}

.settingsContainer {
  margin-bottom: 40px;
}

.settingsHeader {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding-bottom: 16px;
}

.settingsInfo {
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $primary-text-color;
}

.dialogDescription {
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  color: $cyan-blue;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.dialogDescriptionBold {
  font-family: $lincoln-proxima-nova-bold;
  font-size: 16px;
  color: $cyan-blue;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.dialogActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nonStyledListItem {
  @extend .dialogDescription;
  padding: 5px 30px;
}

.languageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

.closeDialogBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.warning {
  margin: 0 5px;
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/warning.svg');
}

.titleContainer {
  display: flex;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 36px;
}

.descriptionContainer {
  margin-bottom: 40px;
}

.buttonContainer {
  width: 48%;
}

.settings {
  background: #fafafa;
  padding: 0 12% 35px 12%;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 18px;
  line-height: 26px;
  color: $cyan-blue;
  white-space: pre-line;
}

.icon {
  position: absolute;
  right: 30px;
  top: 31px
}

.checkboxDiv {
  margin-bottom: 40px;
}

.textfield {
  width: 250px;
  text-align: left;
  justify-content: center;
}

.brandIcon {
  top: calc(50% - 12px);
  right: 0;
  color: $cyan-blue;
  position: absolute;
  padding-right: 10px;
  cursor: pointer;
  pointer-events: none;
}

.popupSection {
  padding-bottom: 16px;
}

.arrow {
  padding-left: 5px;
}

.changeDealer {
  font-size: 9px;
  letter-spacing: 1px;
  padding-bottom: 6px;
}

button.cancelledDialogButton, button.cancelledDialogButton:hover {
  height: 50px;
  width: 100%;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $cyan-blue;
  box-shadow: none;
  border-radius: 0;
  text-transform: none;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: $french-rose;
  text-underline-offset: 4px;
}

button.confirmDialogButton, button.confirmDialogButton:hover {
  height: 50px;
  width: 100%;
  background-color: $cyan-blue;
  color: $primary-bg-color;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border-radius: 0;
  text-transform: none;
}

button.confirmDialogButton:disabled {
  background-color: #949c9f;
  color: white;
}

.chevronOffset {
  transform: translateY(2px);
}
