@import "../../styles/empCssLibs/variables";

.footerBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footerTitleBox,
  .helpContainer {
    flex: 0 0 100%;
    margin: 10px 0;

    @media screen and (min-width: 1024px) {
      flex: 0 0 auto;
      margin: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;

      li {
        flex: 0 0 auto;
        padding: 0;
        margin: 0 16px 0 0;
        box-sizing: border-box;

        &:last-child {
          margin: 0;
        }

        a,
        button {
          font-size: 13px;
          line-height: 1;
          color: #4d4d4d;
          text-decoration: none;
          padding: 0;
          border: none;
          transition: color 0.2s ease-in-out;
          background: none;
          outline: none;

          &:hover {
            color: #0068d0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
