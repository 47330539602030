@import "../../../styles/empCssLibs/variables";

.notificationHeader {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.94;
  letter-spacing: 2.25px;
  color: $button-primary-bg-color;
  padding-top: 36px;
  text-transform: uppercase;

  h2 {
    font-size: 20px;

    font-weight: 500;
    letter-spacing: 1.5px;
    color: $primary-text-bold-color;
  }
}

.pendingActionsHeader {
  @extend .notificationHeader;
  padding-top: 8px;
}

.tableHeaderLine th {
  border-bottom: solid 1px $secondary-border-color;
  height: 25px;
}

.align {
  display: flex;
  align-items: flex-end;
  border-bottom-color: $button-primary-bg-color;
}

.alignEnd {
  @extend .align;
  justify-content: flex-end;
}

.alignStart {
  @extend .align;
  justify-content: flex-start;
}

.alignCenter {
  @extend .align;
  justify-content: center;
}

.actionName {
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.56;
  letter-spacing: 1.69px;
  color: $blue-zodiac;
}

.actionIcon {
  margin: 0px 20px 4px 0px;
}

.actionIconOrders {
  margin: 0px 20px -8px 0px;
}

.action {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.17;
  letter-spacing: 2.25px;
  color: $blue-zodiac;
}

.description {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.91;
  letter-spacing: 0.92px;
  color: $blue-zodiac;
}

.dueDate {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.73;
  letter-spacing: 0.79px;
  color: $tundora-light;
}

.sortUpFilled {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/sort_up_filled.svg");
  display: block;
  margin: 0 3px;
}

.pagesDiv {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 1.14px;
  color: $secondary-text-color;
  margin: 0 30px;
}

.buttonStyle {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
}

.buttonStyle:focus {
  outline: none;
}

.sortUp {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/sort_up.svg");
  display: block;
  margin: 0 3px;
}

.sortDownFilled {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/sort_down_filled.svg");
  display: block;
  margin: 0 3px;
}

.sortDown {
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/sort_down.svg");
  display: block;
  margin: 0 3px;
}

.textContentBodyListRead,
.textContentBodyListNew,
.textContentPendingActions {
  padding: 0 6%;
}

.textContentPendingActions {
  td {
    font-size: 11px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.91;
    letter-spacing: 0.92px;
    color: $button-primary-bg-color;
  }

  .col2 {
    font-weight: normal;
  }

  .col3 {
    font-weight: normal;
    color: $secondary-text-color;
  }

  .col4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.17;
    letter-spacing: 2.25px;
    text-decoration: none;
  }
}

.textContentBodyListRead {
  td {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.91;
    letter-spacing: 0.92px;
    color: $button-primary-bg-color;
  }

  .col3 {
    color: $secondary-text-color;
  }

  .col4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.17;
    letter-spacing: 2.25px;
    text-decoration: none;
  }
}

.textContentBodyListNew {
  td {
    font-size: 11px;
    font-weight: bolder;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.91;
    letter-spacing: 0.92px;
    color: $button-primary-bg-color;
  }

  .col3 {
    color: $secondary-text-color;
  }

  .col4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.17;
    letter-spacing: 2.25px;
    text-decoration: none;
  }
}

.tableLink {
  text-decoration: none;
  text-align: right;
  color: $primary-text-bold-color;
  font-size: 12px;
  font-weight: bold;
  line-height: 2.17;
  letter-spacing: 2.25px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.goToPage {
  @extend .tableLink;
  letter-spacing: 1px;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursorButton :hover {
  cursor: pointer;
}

.center,
.center:visited {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 6px;
  border-bottom-color: $button-primary-bg-color;
  text-decoration: none;
  color: $primary-text-bold-color;
}

.textContentPendingActions.red {
  background-color: rgba(225, 37, 70, 0.1);

  td {
    border-bottom: 1px solid white;
  }
}

.textContentPendingActions.amber {
  background-color: rgba(240, 153, 60, 0.1);

  td {
    border-bottom: 1px solid white;
  }
}

.wbdoLink {
  padding: 10px 6%;

  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 1px;
  color: $primary-text-color;
}

.wbdoLink a {
  color: $primary-text-color;
  font-weight: bold;
}

.wbdoLink a:visited {
  color: $primary-text-color;
}

.comingSoonContainer {
  max-width: 850px;
  margin: 75px auto;
  padding: 20px 30px;
  box-shadow: inset 0px 0px 0px 1px $secondary-border-color;
}

.comingSoonTitle {
  font-size: 30px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 3px;
  color: $secondary-text-color;
}

.comingSoonPrimaryText {
  @extend .dueDate;
  font-size: 12px;
}

.comingSoonSecondaryText {
  @extend .dueDate;
  font-size: 12px;
  font-weight: bold;
}

.blueBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-text-bold-color;
  padding: 16px 24px;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;
  }
}
