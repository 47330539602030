@import "../../../styles/empCssLibs/variables";

.previewPDFWrapper {
  margin: 35px 5% 15px 5%;
  padding: 15px 0 15px;

  .previewPDFTitle {
    color: $primary-text-bold-color;
    text-transform: uppercase;

    font-weight: 500;
    line-height: 2.08;
    letter-spacing: 1.5px;
    text-align: center;
  }

  .effectiveFrom {
    letter-spacing: 1.5px;
    text-align: center;
    color: $secondary-text-color;
    text-transform: uppercase;
    margin-bottom: 35px;
  }

  div.previewPDF {
    height: 500px;
    overflow-y: scroll;
    padding: 1px;
    border-radius: initial;
    border: 1px solid $secondary-border-color;
  }
}

.backButton {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-transform: initial;
  margin-left: -15px;

  .arrowLeft {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid black;
    margin-right: 10px;
  }
}

.termsConditionsHeader {
  font-stretch: normal;
  color: $secondary-text-color;
  height: 35px;
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 50px;
}

.acceptanceSelector {
  background-color: $section-secondary-header;

  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  text-align: center;
  padding: 40px 0 40px 0;
  margin-bottom: 40px;
}

.dialogActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30% 30px 30%;
}

.downloadNewVersion {
  padding-left: 5%;
  text-align: left;

  font-size: 12px;
  font-weight: 500;
  line-height: 2.17;
  margin-bottom: 15px;

  span {
    color: $button-primary-bg-color;
    cursor: pointer;
    text-decoration: underline;
  }
}
