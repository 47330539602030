@import "../../../styles/empCssLibs/variables";

.pagePadding {
  box-sizing: border-box;
  padding: 32px 24px;

  @media screen and (min-width: 1024px) {
    padding: 32px 56px;
  }
}

.card {
  .contentOrange {
    color: $brown-orange;
  }

  .contentGreen {
    color: $dark-green;
    min-height: 250px;
  }

  .cardNumber {
    min-height: 120px;
    margin: 0;

    .bigTotal {
      text-align: center;
      font-size: 100px;
      font-weight: 500;
      margin: 0;
    }

    .bigDescription {
      text-align: center;
      font-size: 13px;
      line-height: 1.5;
      margin: 0;
    }
  }

  .breakdown {
    background-color: $gray86;
    border-radius: 5px;
    text-align: left;
    padding: 9px 4px 8px;

    .breakdownItem {
      padding: 10px 10px;
      color: $primary-text-color;
      font-size: 11px;
    }

    .breakdownNumber {
      padding: 0 5px;
      font-weight: bold;
    }
  }

  .cardActions {
    padding: 20px 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 120px;
    margin: 0 0 24px 0;

    .bigDescription {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
      text-align: center;
      font-size: 12px;
      line-height: 1.5;
      font-weight: bold;
      padding-left: 5px;
      padding-right: 5px;
      margin: 0;
    }

    .breakdown {
      background-color: $gray86;
      border-radius: 5px;
      text-align: left;
      padding: 9px 4px 8px;

      .breakdownItem {
        padding: 10px 10px;
        color: $primary-text-color;
        font-size: 11px;
      }

      .breakdownNumber {
        padding: 0 5px;
        font-weight: bold;
      }
    }

    .cardActions {
      padding: 20px 16px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-height: 120px;

      .button {
        flex: 0 0 100%;
        font-size: 15px;
        border-radius: 50px;
        border: solid 1px #102b4e;
        padding: 8px 20px;
        margin: 0;
        transition: 0.2s ease;
        letter-spacing: 0;

        &:hover {
          background-color: $primary-text-bold-color;
          color: $white;
        }
      }

      .hiddenButton {
        @extend .button;
        visibility: hidden;
      }
    }

    .smallButton {
      border: none;
      background: transparent;
      cursor: pointer;

      font-size: 14px;
      text-decoration: underline;
      font-weight: 300;
      color: $primary-text-bold-color;
      text-transform: capitalize;
      margin-top: 10px;
    }
  }

  .subHeader {
    font-size: 13px;
    color: #4a4a4a;
  }

  .styledList {
    padding: 0;
    margin: 0 !important;

    li {
      padding-left: 0;
      text-indent: 0;
      list-style: none;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .dashboardCardsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
