@import "../../styles/empCssLibs/variables";
@import "../../styles/common/CommonStyles.module";

.greyBackground {
  background-color: #f2f2f2;
}

.brandLogo {
  padding-top: 5px;
  color: $primary-text-bold-color;
  height: 60px;
  margin-left: -10px;
}

.dealerDetails {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.dealerType {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1px;
  text-align: right;
  color: #6e6e6e;
  text-transform: capitalize;
}

.dealerCommonId {
  @extend .dealerType;
}

.dealerName {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 3px;
  text-align: right;
  color: $primary-text-bold-color;
  text-transform: uppercase;
}

.pageHeader {
  height: 70px;
  padding: 0 6%;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: white;
}

.blueBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-text-bold-color;
  padding: 16px 24px;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;
  }

  .blueBar {
    display: block;
    text-align: left;

    @media screen and (min-width: 1024px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 1 80%;
    }

    .searchText {
      text-align: left;

      font-size: 14px;
      color: #ffffff;
      margin: 0 0 16px 0;

      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
        flex: 0 0 auto;
        margin-right: 8px;
      }
    }

    form.searchWrapper {
      flex: 0 1 400px;
      position: relative;
      margin-bottom: 16px;

      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
      }

      input.inputField {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 8px 32px 8px 8px;
        background-color: #f6f6f6;

        letter-spacing: normal;
        color: $primary-text-bold-color;
        text-align: left;
        margin: 0;
        z-index: 0;

        &::placeholder {
          font-size: 14px;
        }

        &::-ms-clear {
          display: none;
        }
      }

      button.searchButton {
        position: absolute;
        top: 0;
        right: 6px;
        width: 24px;
        height: 40px;
        padding: 0;
        border: none;
        background: transparent;
        z-index: 1;

        &:focus {
          outline: none;
        }

        .searchIcon {
          padding: 0;
          margin: 0;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-image: url("../../imgs/search.svg");
        }

        .closeIcon {
          cursor: pointer;
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../imgs/close_Cancel.svg");
        }
      }
    }
  }

  .iconButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      .archiveLink {
        text-decoration: underline;
      }
    }

    .archiveLink {
      flex: 0 0 auto;

      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      color: #ffffff;
      cursor: pointer;
    }

    .archiveIcon {
      width: 16px;
      margin-right: 5px;
    }
  }
}

.resultsWrapper {
  display: flex;
  flex-direction: column;
}

.noResultsFound {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin: 20px 0 41px 0;
}

.noResultsDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeDialogBtn {
  height: 40px;
  border: none;
  background: transparent;
  position: absolute;
  top: 0;
  right: 5%;
  cursor: pointer;
}

.configurations {
  font-size: 10px;
}

.specsWrapper {
  letter-spacing: normal;
}

.nameplate {
  font-size: 12px;
  letter-spacing: 0.86px;
}

.specs {
  font-size: 10px;

  letter-spacing: 0.71px;
}

.specsWrapperORA {
  line-height: 1.4;
}

.orderStatusWrapper {
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.orderStatusWrapper span {
  margin: 2px;
}

.tableHead th {
  border-bottom: 1px solid $dusty-gray-light;
}

.tableBody td {
  background-color: white;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: $secondary-text-color;
  border-bottom: 1px solid $dusty-gray-light;
}

.tableLink {
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: $primary-text-bold-color;
  letter-spacing: 0.71px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
}

.detailsLink {
  font-size: 12px;
  font-weight: bold;
  color: $primary-text-bold-color;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.convertLink {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.88px;
  text-align: center;
  padding-top: 3px;
  color: $blue-zodiac;
}

.convertReadOnlyLink {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.88px;
  text-align: center;
  padding-top: 3px;
  color: #c8c8c8;
}

.convertDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.convertReadOnlyDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.hover {
  cursor: pointer;
}

.acceptDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 5px 0;
}

.depositDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
}

.actionRequiredDiv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0;
}

.acceptDivReadOnly {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px 0;
}

.messageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: initial;
}

.orderTypeDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.oraTypeText {
  font-size: 12px;
  letter-spacing: 0.86px;
  color: #ed8231;
}

.resSubmittedTypeText {
  font-size: 12px;
  letter-spacing: 0.86px;
  color: #5c97ea;
}

.purchaseRequestText {
  font-size: 12px;
  margin-bottom: 8px;
  color: #5c97ea;
}

.orderTypeLink {
  font-size: 12px;
  color: $blue-zodiac;
  text-decoration: none;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.status {
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.statusText {
  @extend .rejectButtonText;

  font-weight: 600;
  letter-spacing: 1.7px;
  color: #4a4a4a;
}

.acceptButtonText {
  @extend .acceptRejectButtontext;
  color: #3b822e;
}

.rejectButtonText {
  @extend .acceptRejectButtontext;
  color: #a51426;
}

.acceptRejectButtontext {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1.88px;
  text-align: center;
  padding-top: 3px;
}

.rejectButtonTextDisabled {
  @extend .rejectButtonText;

  color: #c8c8c8;
}

.acceptButtonTextDisabled {
  @extend .rejectButtonTextDisabled;
}

.alignStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pointer {
  cursor: pointer;
}

.alignCenter {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.alignEnd {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom-color: $primary-text-bold-color;
}

.tabs {
  padding: 48px 24px 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 1024px) {
    padding: 48px 48px 16px;
  }

  .unSelectedTab {
    font-size: 13px;
    color: $bold-gray;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    padding: 8px 16px;
    text-align: center;
    border-radius: 30px;

    &:hover {
      background: #f3f3f3;
      color: $blue-zodiac;
    }

    &:last-of-type {
      flex: 0 0 auto;
    }

    @media screen and (min-width: 1024px) {
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.selectedTab {
      background: $primary-text-bold-color;
      color: white;
      border: 1px solid $primary-text-bold-color;
      cursor: initial;
    }
  }
}

.ordersHeader {
  padding: 0 6%;
}

.backButton {
  background-color: #ffffff;
  padding-left: 5%;
  padding-bottom: 10px;
  padding-top: 40px;
  margin-top: 1px;
}

.closeButton {
  align-items: flex-end;
  background-repeat: no-repeat;
  background-image: url("../../imgs/close_Cancel.svg");
}

.dialogDescription {
  flex-shrink: 0;
  margin-bottom: 20px;
  padding: 0 6%;

  font-size: 14px;
  line-height: 1.93;
  letter-spacing: 1.5px;
  color: #575757;
}

.dialogDescription ul {
  padding: 0;
}

.dialogDescription li {
  list-style-position: outside;
  margin-left: 1em;
  padding-left: 12px;
}

.dialogTitle {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 40px 0 10px 0;
}

.dialogHeader {
  color: #a81d2e;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 4px;
  padding-left: 6%;
}

.cancelButton {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: $primary-text-bold-color;

  font-size: 18px;
  color: white;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: 1px;
  cursor: pointer;
}

.confirmButton {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: $primary-text-bold-color;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: 1px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 25px;
}

.confirmButtonDisabled {
  display: flex;
  align-items: center;

  font-size: 18px;
  color: grey;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: 1px;
  border: none;
  background-color: transparent;
  margin-left: 25px;
}

.buttonDiv {
  text-align: center;
}

.optionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  padding: 0 6%;
  margin-top: 10px;
  margin-bottom: 40px;
}

.chevron {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $primary-text-bold-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevronDisabled {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropDownContainer {
  padding: 30px 0 0 0;
  background: $primary-bg-color;
}

.headerBar {
  width: 100%;
  line-height: 2.5;
  padding: 5px 6% 0 6%;
  display: flex;
  align-items: center;

  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.expansionIcon {
  padding: 5px 0 0 5%;
}

.warning {
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/warning-blue.svg");
}

.consent {
  font-weight: bold;
  font-size: 17px;
  color: black;
}

.redBox {
  border: 3px solid red;
  padding: 7px;
}

.vehicleHold {
  height: 46px;
  width: 46px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/purchaseRequest_action/vehicle-hold.svg");
  cursor: pointer;
  margin: 0px 2px;
}

.tradeIn {
  height: 46px;
  width: 46px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/purchaseRequest_action/trade-in.svg");
  cursor: pointer;
  margin: 0px 2px;
}

.holdDeposit {
  height: 46px;
  width: 46px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/purchaseRequest_action/hold-deposit.svg");
  cursor: pointer;
  margin: 0px 2px;
}

.requestAccept {
  height: 46px;
  width: 46px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/purchaseRequest_action/accept-request.svg");
  cursor: pointer;
  margin: 0px 2px;
}

.pagesDiv {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  font-size: 16px;
  line-height: 1.63;
  letter-spacing: 1.14px;
  color: $secondary-text-color;
  margin: 0 30px;

  .paginationNumbers {
    font-size: 16px;
    min-width: 30px;
    margin: 0 4px;
    padding: 8px;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
      border-radius: 4px;
    }
  }

  .paginationNumbers.active {
    background-color: #dedede;
    border-radius: 4px;

    &:hover {
      cursor: default;
    }
  }
}

.backButtonDiv {
  margin: 30px 5% 10px 4%;
}

.backToOrderText {
  color: $primary-text-bold-color;
  font-weight: 800;
  letter-spacing: 2.17px;
  font-size: 12px;
  margin: 0 10px;
  text-transform: uppercase;
}

.modeleTableWrapper {
  padding: 16px;

  @media screen and (min-width: 1440px) {
    padding: 56px;
  }

  .expandContainer {
    margin: 15px 0 10px 0;
    font-size: 13px;

    > * {
      margin-right: 10px;
      cursor: pointer;
      color: #666666;
    }
  }

  .modeletable {
    .alignCenter {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .tableLink {
      text-align: left;
    }
  }
}
