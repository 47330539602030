/** =============================================================================================
**  App level Layout styles==============================================================================
**  ==========================================================================================**/
.fullHeightLayout {
  height: 100vmin;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .fullHeightItem {
    max-width: 1440px;
    flex: 0 0 auto;
    width: 100%;
    margin: 0 auto;

    &:nth-child(2) {
      flex: 1 0 auto;
      background: white;
      height: auto;
    }

    &:last-child {
      height: auto;
    }
  }
}
