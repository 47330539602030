@import "../../../styles/empCssLibs/variables";
@import "../../../styles/common/CommonStyles.module";

.customerHeading {
  margin-bottom: 16px;
}

.whiteBg {
  background-color: $header-bar-text-color;
}

.greyBg {
  background-color: #f3f3f3;
}

.statusMessage {
  color: $blue-zodiac;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  margin: 20px 0;
  text-align: center;
}

.backToOrderText {
  color: $primary-text-bold-color;
  font-weight: 800;
  letter-spacing: 2.17px;
  font-size: 12px;
  margin: 0 10px;
  text-transform: uppercase;
}

.newOrdersDiv {
  font-size: 22px;
  font-weight: 500;
  line-height: 2.19;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
  display: flex;
  align-items: center;
}

.componentContainer {
  padding: 10px 25px;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    padding: 10px 50px;
  }

  .expandContainer {
    margin: 15px 0 10px 0;
    font-size: 13px;

    > * {
      margin-right: 10px;
      cursor: pointer;
      color: #666666;
    }
  }
}

.expansionBox {
  background: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

.headerBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerBarNoIdent {
  @extend .headerBar;
  padding: 0;
}

.boldHeader {
  font-size: 16px;
  color: $primary-text-bold-color;
}

.header {
  color: $primary-text-bold-color;
  font-size: 12px;
  text-transform: uppercase;

  .headerStatus {
    color: $dark-grey;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  .iconContainer {
    display: inline-block;
    font-size: 0;
    vertical-align: text-top;
    margin-left: 3px;
  }
}

.acceptRejectDiv {
  display: flex;
  align-items: flex-end;
}

.customerInfoGrid {
  padding: 16px 32px;
}

.bottomBorder {
  border-bottom: 1px solid $primary-text-bold-color;
  margin-bottom: 16px;
}

.customerContainer {
  padding: 25px 0;
}

.cardGridItemWrapper {
  flex: 1 1 auto;
}

.label {
  font-size: 12px;
  font-weight: 500;
  line-height: 2.08;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
}

.labelError {
  @extend .label;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.subHeaderText {
  font-size: 16px;
  color: $primary-text-bold-color;
  margin-bottom: 20px;
}

.headerText {
  font-stretch: normal;
  font-style: normal;
  color: $primary-text-bold-color;
  height: 29px;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 600;
  text-transform: uppercase;
}

.subHeaderSpan {
  @extend .subHeaderText;
  padding-bottom: 14px;
}

.subHeaderRow {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.itemHeaderText {
  color: $secondary-text-color;

  .iconContainer {
    display: inline-block;
    font-size: 0;
    vertical-align: text-top;
    margin-left: 3px;
  }
}

.itemValueText {
  color: $secondary-text-color;
  padding-bottom: 15px;
  overflow-wrap: break-word;
}

.itemValueCapitalizeText {
  @extend .itemValueText;
  text-transform: none;
}

.customerInfoContainer {
  padding-bottom: 15px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin-bottom: 6px;

      p {
        margin: 0;
        line-height: 1;
        color: $primary-text-color;
      }
    }
  }
}

.customerInfoText {
  color: $primary-text-color;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  display: flex;
  flex-direction: column;
}

.customerInfoListValue {
  color: $primary-text-color;
  font-size: 14px;
  line-height: 24px;
  display: block;
}

.customerInfoTextBold {
  @extend .customerInfoText;
  font-weight: bold;
}

.itemValueEmailText {
  font-size: 13px;
  font-weight: 300;
  line-height: 2.08;
  letter-spacing: 2px;
  color: $secondary-text-color;
  text-decoration: underline;
}

.customerInfoBar {
  padding: 0 1rem;
  font-size: 14px;

  &:first-of-type {
    padding: 0 1rem 0 0;
  }
}

.customerInfoDetails {
  @extend .customerInfoBar;
  border-right: 2px solid grey;
}

.pricingSummaryExpansion {
  @extend .headerBar;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  line-height: 2.5;
  text-transform: uppercase;
}

.featuresExpansion {
  @extend .pricingSummaryExpansion;
  width: auto;
}

.featuresExpansionNoIndent {
  color: $primary-text-bold-color;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: 2.5px;
  font-weight: 500;
  line-height: 2.5;
  text-transform: uppercase;
  width: auto;
}

.pricingSummaryExpansionSubheader {
  width: 100%;
  padding: 0 32px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: bold;
  line-height: 2.5;
  text-transform: uppercase;
}

.dealerPrice {
  display: flex;
  justify-content: flex-end;
  padding-left: 2%;
}

.priceDetailsIndent {
  padding-left: 32px;
}

.priceDetails {
  text-transform: uppercase;
}

.lightHeader {
  color: $primary-text-bold-color;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2.5px;
  line-height: 3.5;
}

.darkHeader {
  @extend .lightHeader;
  background: $smoky-gray;
}

.lightCaseSensitiveHeader {
  @extend .lightHeader;
  text-transform: none;
  color: $tundora;
}

.darkCaseSensitiveHeader {
  @extend .lightHeader;
  text-transform: none;
  color: $tundora;
  background: $smoky-gray;
}

.darkCaseSensitiveBoldHeader {
  @extend .header;
  background: $smoky-gray;
  line-height: 3.5;
}

.rightAlign {
  text-align: right;
}

.leftAlign {
  text-align: left;
}

.leftSpacing {
  padding-left: 32px;
}

.rightIndent {
  padding: 0 32px 0 6px;
}

.specsContainer {
  width: 100%;
}

.cancelDiv,
.refundDiv {
  text-align: right;
}

.cancelDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .messageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: initial;
  }
}

.cancelButton,
.refundButton {
  width: 50%;
  min-height: 25px;
  height: auto;
  background-color: $primary-text-bold-color;
  cursor: pointer;
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1px;
  border-radius: 20px;
  border: solid 2px $primary-text-bold-color;
}

.blueButton {
  @extend .cancelButton;
  background-color: #164069;
  white-space: nowrap;
  width: auto;
  border: none;
  padding: 3px 25px;
}

.acceptOrderButton {
  @extend .cancelButton;
  background-color: #164069;
  border: none;
  width: auto;
  padding: 3px 25px;

  &:disabled {
    background-color: #9b9b9b;
    color: white !important;
    opacity: 0.7;
  }
}

.rejectOrderButton {
  @extend .acceptOrderButton;
  background-color: $white;
  color: #164069;
  border: solid 2px #164069;

  &:disabled {
    background-color: $white;
    border-color: #9b9b9b;
    color: #9b9b9b !important;
    opacity: 0.7;
  }
}

.cancelButton:focus,
.refundButton:focus {
  outline: none;
}

.cancelButton:disabled,
.refundButton:disabled,
.updateBtn:disabled {
  color: gray;
  cursor: auto;
}

.blueButton:disabled {
  @extend .cancelButton;
  background-color: lightgray;
}

.refundBtn:disabled {
  visibility: hidden;
}

.darkIndentDetails {
  @extend .darkHeader;
}

.lightIndentDetails {
  @extend .lightHeader;
}

.darkIndentDetails .leftIndent {
  padding-left: 12%;
}

.infoIcon {
  background-color: $white;
  border: 1px solid $soft-blue;
  height: 20px;
  width: 20px;
  border-radius: 25px;
  font-size: 12px;
  color: $soft-blue;
  font-weight: bold;
  text-transform: lowercase;
}

.leftIndent button {
  padding: 0;
  margin-left: 10px;
  min-width: 20px;
}

.tooltipHeader {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $primary-text-bold-color;
}

.tooltipBody {
  font-size: 12px;
  text-transform: none;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  margin-top: 12px;
}

.closeButton {
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/close_Cancel.svg");
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 12px;
}

.indent {
  padding-left: 32px;
  padding-right: 32px;
}

.baseIndent {
  padding-left: 2%;
}

.mediumIndent {
  padding-left: 32px;
}

.rightIndent {
  padding-right: 64px;
}

.smallRightIndent {
  padding-right: 2.2%;
}

.largeIndent {
  padding-left: 6%;
}

.printText {
  color: $primary-text-bold-color;
  letter-spacing: 2.17px;
  font-size: 12px;
}

.printIcon {
  color: $primary-text-bold-color;
}

.detailsContainer {
  @extend .newOrdersDiv;
  padding: 20px 25px;

  @media screen and (min-width: 1024px) {
    padding: 20px 50px;
  }

  .orderDetails {
    letter-spacing: 1.5px;
    line-height: 1;
  }

  .customerDetails {
    text-align: left;
    line-height: normal;

    @media screen and (min-width: 768px) {
      text-align: right;
    }

    span {
      display: inline-block;
      letter-spacing: 1.5px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.leftPadding {
  padding-left: 5%;
}

.rightPadding {
  padding-right: 5%;
  float: right;
}

@media print {
  .printIcon {
    visibility: hidden;
  }
  .printText {
    visibility: hidden;
  }
}

.sectionHeader {
  margin: 25px auto;
  width: 90%;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
}

.chargingHeader {
  padding: 0 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chargingIconDiv {
  display: flex;
}

.chargingRow {
  padding: 0 calc(6% + 25px) 0 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.updateBtn,
.refundButtonSmall,
.refundBtn {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 9px;
  text-decoration: underline;
  font-weight: 300;
  line-height: 2.08;
  letter-spacing: 2px;
  color: $primary-text-bold-color;
  text-transform: uppercase;
  padding-left: 10px;
}

.refundButtonSmall:disabled {
  color: gray;
  cursor: auto;
}

.refundDepositNA {
  @extend .refundButtonSmall;
  letter-spacing: 1px;
}

.disabledRefundDepositNA {
  @extend .refundButtonSmall;
  letter-spacing: 1px;
  color: $dark-grey;
  cursor: auto;
}

.bodyContent {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  margin-bottom: 15px;
}

.acceptPurchaseNAButton {
  background-color: $primary-text-bold-color;
  border: solid 2px $primary-text-bold-color;
  cursor: pointer;
  color: $white;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1px;
  border-radius: 20px;
  height: auto;
  width: auto;
  padding: 3px 25px;
}

.cancelPurchaseNAButton {
  @extend .cancelButton;
  background-color: white;
  color: $primary-text-bold-color;
  width: auto;
  padding: 3px 25px;
}

.acceptPurchaseNAButton:disabled,
.cancelPurchaseNAButton:disabled {
  background-color: $gray90;
  border: solid 1px $gray90;
}

.smallHeaderMargin {
  margin-top: 5px;
}

.errorText {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.94;
  letter-spacing: 2.25px;
  color: $button-primary-bg-color;
  padding-top: 36px;
  padding-left: 6%;
  padding-right: 6%;
  box-sizing: border-box;
  text-transform: uppercase;

  h2 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: $primary-text-bold-color;
  }
}

@media print {
  .noPrint {
    display: none !important;
  }
}

.itemHeaderSpan {
  padding-top: 10px;
}

.windowStickerLink {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 11px;
  text-decoration: underline;
  line-height: 1px;
  letter-spacing: 2px;
  color: $primary-text-bold-color;
  text-transform: none;
}

.floatingActionButtonDiv {
  display: block;
  position: fixed;
  z-index: 1;
  bottom: 100px;
  right: 50px;
}

.floatingDiv {
  width: 79%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  z-index: 2;
  margin-top: 900px;
}

button.floatingActionButton,
button.floatingActionButton:hover {
  background-color: $button-primary-bg-color;
  color: $primary-bg-color;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 3px;
  text-align: center;
  font-size: 13px;
  border-radius: 20px;
}

.blueBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-text-bold-color;
  padding: 16px 24px;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 48px;
  }

  .blueBar {
    display: block;
    text-align: left;

    @media screen and (min-width: 1024px) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 0 1 80%;
    }

    .searchText {
      text-align: left;
      font-size: 14px;
      color: #ffffff;
      margin: 0 0 16px 0;

      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
        flex: 0 0 auto;
        margin-right: 20px;
      }
    }

    form.searchWrapper {
      flex: 0 1 400px;
      position: relative;
      margin-bottom: 16px;

      @media screen and (min-width: 1024px) {
        margin-bottom: 0;
      }

      input.inputField {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 8px 32px 8px 8px;
        background-color: #f6f6f6;
        letter-spacing: normal;
        color: $primary-text-bold-color;
        text-align: left;
        margin: 0;
        z-index: 0;

        &::placeholder {
          font-size: 14px;
        }

        &::-ms-clear {
          display: none;
        }
      }

      button.searchButton {
        position: absolute;
        top: 0;
        right: 6px;
        width: 24px;
        height: 40px;
        padding: 0;
        border: none;
        background: transparent;
        z-index: 1;

        &:focus {
          outline: none;
        }

        .searchIcon {
          padding: 0;
          margin: 0;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          background-image: url("../../../imgs/search.svg");
        }

        .closeIcon {
          cursor: pointer;
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-image: url("../../../imgs/close_Cancel.svg");
        }
      }
    }
  }

  .iconButton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      .archiveLink {
        text-decoration: underline;
      }
    }

    .archiveLink {
      flex: 0 0 auto;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1.5px;
      color: #ffffff;
      cursor: pointer;
    }

    .archiveIcon {
      width: 16px;
      margin-right: 5px;
    }
  }
}

.appointmentFulfilled {
  display: flex;
}
