@import "../../../styles/empCssLibs/variables";

.topPadding {
  padding-top: 25px;
}

.divPadding {
  padding-top: 2px;
}

.padding {
  padding: 10px 0;
}

.expPanelNoBoxShadow {
  box-shadow: none !important;
}

.baseButton {
  height: 35px;
  width: 35px;
  min-width: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.headerBar {
  width: 100%;
  font-size: 14px;

  letter-spacing: 2.25px;
  padding: 0 6%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-bold-color;
}

.sectionHeaderDealership {
  font-size: 14px;
  margin: 10px 0;
  font-weight: bold;
  color: $primary-text-bold-color;
}

.sectionHeaderEmployees {
  @extend .sectionHeaderDealership;
  margin: 0;
}

.businessDetailsContainer {
  padding: 10px 6% 0 6%;
  font-size: 11pt;
  margin-bottom: 50px;
}

.subHeader {
  border-bottom: 1px solid $section-subheader-border-color;

  color: $primary-text-bold-color;
  font-weight: bold;
  font-size: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 1.13px;
}

.businessDetailsInputGridModel {
  color: $primary-text-bold-color;
}

.divAddress {
  padding: 10px 0;

  font-weight: normal;
  font-size: 12px;
  line-height: 1.92;
  letter-spacing: 1.2px;
  color: $secondary-text-color;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.divEmail {
  padding: 10px 0;
  display: flex;
}

.emailText {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.86px;
  color: $primary-link-color;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.divPhone {
  padding: 10px 0;
  display: flex;
}

.phoneText {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.2px;
  color: $secondary-text-color;
}

.employeeEmailHeader {
  padding: 0 6%;
  font-size: 12pt;

  button[id^="updateEmployee"] {
    @extend .baseButton;
  }

  button[id^="cancelEdit"] {
    @extend .baseButton;
  }
}

.employeeInfoHeader {
  text-align: center;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.notifications {
  @extend .employeeInfoHeader;
  text-align: left;

  > div {
    width: 96px;
  }
}

.tableBody {
  font-weight: normal;
  align-items: first baseline;
}

.confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border: 1px solid $primary-text-bold-color;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.17;
  color: $primary-text-bold-color;
}

.employeeInfo {
  border-bottom: 1px solid $section-header-bg;
  align-items: center;

  button[id^="editEmployee_"] {
    @extend .baseButton;
  }

  button[id^="deleteEmployee_"] {
    @extend .baseButton;
    color: $button-primary-bg-color;
  }

  > div[id^="email_"] {
    color: $primary-link-color;
  }
}

.addressFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.employeeRow {
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  padding: 10px 0;
}

.employeeName {
  @extend .employeeRow;
  font-weight: normal;
  letter-spacing: 1.06px;
  color: $secondary-text-color;
}

.employeeEmail {
  @extend .employeeRow;
  font-weight: 600;
  letter-spacing: 0.86px;
  color: $primary-link-color;
}

.employeeSwitches {
  @extend .employeeRow;

  font-weight: 600;
  letter-spacing: 0.86px;
  text-align: left;
  color: #888e94;
}

.editDiv {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.switches {
  text-align: center;
  padding: 10px 0;
}

.newEmployeeContainer {
  padding-top: 10px;
  height: 50px;

  .actionDiv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  button[id^="submit_"] {
    @extend .baseButton;
  }

  button[id^="cancelAdd_"] {
    @extend .baseButton;
  }
}

.editButton > button {
  font-size: 12px;
  letter-spacing: 1.2px;
  color: $primary-text-bold-color;
  margin: 5px 0;
  padding: 5px;
}

.contactEditDivNoAddress {
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;

  button[id^="Edit_"] {
    @extend .baseButton;
    position: relative;
  }

  button[id^="confirmInfo_"] {
    @extend .baseButton;
  }

  button[id^="cancelInfo_"] {
    @extend .baseButton;
  }
}

.contactEditDiv {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  button[id^="Edit_"] {
    @extend .baseButton;
    position: relative;
    right: 35px;
  }

  button[id^="confirmInfo_"] {
    @extend .baseButton;
  }

  button[id^="cancelInfo_"] {
    @extend .baseButton;
  }
}

.maximumEmployee {
  font-size: 12px;
  letter-spacing: 1.06px;
  color: $warning-text-color;
  margin-top: 10px;
}
