@import "../../../styles/empCssLibs/variables.scss";

.pagePadding {
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0 56px;
  }
}

.header {
  color: $primary-text-color;
  font-size: 34px;
  margin-top: 30px;
}

.title {
  padding: 10px 0;
  font-size: 12px;
  height: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
}

.priceType {
  display: flex;
  justify-content: flex-start;
  padding-top: 32px;
}

.radio {
  height: 175px;
  width: 320px;
  box-shadow: 0 0 5px 2px rgba(74, 74, 74, 0.05);
  background-image: linear-gradient(
    to bottom,
    $navigation-bg-color,
    $navigation-bg-color
  );
  border: solid 1px $pale-gray;
  margin-right: 2%;
  object-fit: contain;
  display: flex;
  cursor: pointer;
  padding: 10px;
}

.radioTitle {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  padding-top: 12px;
  color: $primary-text-bold-color;
}

.text {
  max-width: 240px;
}

.radioText {
  height: 90px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding-top: 10px;
}

.percentage {
  @extend .alignLeft;
  height: 31px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: $primary-text-color;
}

.currency {
  height: 31px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-weight: 600;
  color: $primary-text-color;
}

.alignLeft {
  text-align: left;
}

.brandHeader {
  padding: 2% 0 5px 0;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  text-align: right;
  color: $primary-text-bold-color;
  line-height: 1.6;
  border-bottom: 3px solid $primary-text-bold-color;
}

.brandText {
  padding-top: 1%;
  height: 40px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  color: $primary-text-color;
  text-align: right;
}

.brand {
  display: flex;
  align-items: center;
}

.brandName {
  @extend .brand;
  color: $primary-text-bold-color;
  @extend .alignLeft;
}

.textBox {
  height: 31px;
}

.textBox input {
  width: 50px;
}

.laborRateRow {
  @extend .brand;
  justify-content: flex-end;
}

.greenButton {
  border: none;
  border-radius: 0;
  padding: 3% 0 0 0;
  justify-content: flex-start;

  button {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.17;
    letter-spacing: 2.3px;
    text-align: center;
    width: 300px;
    border-radius: 0;
    box-shadow: none;
    min-height: 48px;
    height: auto;
    border: none;
    background-color: green;
    background-image: linear-gradient(to bottom, #00ad46, #00913b);
    color: $white;
  }

  button:disabled {
    background-color: rgba(128, 128, 128, 0.25);
    color: $pale-gray;
    background-image: linear-gradient(to bottom, $gray90, $gray90);
  }
}

.overridesDialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 6%;
  text-align: center;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

.closeDialogBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.dialogTitle {
  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 2px;
  color: $secondary-green;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
}

.dialogDescription {
  width: 85%;
  margin: auto;
  font-size: 12px;
  color: $primary-text-color;
  font-stretch: normal;
  font-style: normal;
  padding: 15px 0;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 1.5px;
  height: 50px;
}

.buttons {
  text-align: center;
  padding-top: 2%;

  .brandConfirm {
    width: 60%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(
      to bottom,
      $primary-text-bold-color,
      $primary-text-bold-color
    );
    margin: 5px;
    border-radius: 0;
    padding: 12px 0;
    border: none;

    &:hover {
      cursor: pointer;
    }

    p {
      margin: 0;
      letter-spacing: 1.5px;
      font-size: 14px;
      color: white;
      line-height: 22px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .customiseToBtn {
    width: 60%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(
      to bottom,
      $primary-bg-color,
      $primary-bg-color
    );
    margin: 5px;
    border-radius: 0;
    padding: 12px 0;
    border: none;

    &:hover {
      cursor: pointer;
    }

    p {
      margin: 0;
      letter-spacing: 1.5px;
      font-size: 14px;
      color: $blue-zodiac;
      line-height: 22px;
      font-weight: 500;
    }
  }
}

.tickMarkGreen {
  font-size: 23px;
  padding: 5px;
}
.savedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
