@import "../../styles/empCssLibs/variables";

.subNav {
  .container {
    background: #f0f0f0;
  }

  .welcome {
    font-size: 16px;
    color: $primary-text-color;
    margin: 0;
  }

  .root {
    background: $primary-text-bold-color;
    color: $primary-bg-color;
    padding: 0 16px;

    @media screen and (min-width: 768px) {
      padding: 0 56px;
    }

    .flexContainer {
      height: 50px;
    }

    .blueBar {
      display: block;
      text-align: left;
      padding: 16px 0;

      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 0;
      }

      .searchText {
        text-align: left;

        font-size: 14px;
        color: #ffffff;
        margin: 0 0 15px 0;

        @media screen and (min-width: 1024px) {
          margin-bottom: 0;
          flex: 0 0 auto;
          margin-right: 20px;
        }
      }

      form.searchWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .searchContainer {
          display: flex;
          align-items: center;
        }

        input.inputField {
          box-sizing: border-box;
          width: 100%;
          height: 40px;
          padding: 6px 30px 6px 10px;
          background-color: #f6f6f6;

          letter-spacing: normal;
          color: $primary-text-bold-color;
          text-align: left;
          margin: 0;
          z-index: 0;

          &::placeholder {
            font-size: 14px;
          }

          &::-ms-clear {
            display: none;
          }
        }
      }
    }
  }
}

.dropdownDiv {
  margin: 0 30px;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dealerName {
  padding: 5px 10px;
  margin: 0;
}

.unonboardedDealer {
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}

.unonboardedDealerIcon {
  padding-right: 10px;
}

.subNav .moreTab span {
  font-size: 14px;

  font-weight: 500;
  text-transform: uppercase;
}

.subNav .moreTab {
  box-sizing: border-box;
  padding: 10px 42px 10px 24px;
  min-width: 109px;
  cursor: pointer;
}

#subNavMenuDropdown div li {
  width: 300px;
}

.subNav .root .scrollable {
  height: 67px;
}
