@import "../../../styles/empCssLibs/variables";

.container {
  min-height: 40px;
  margin-bottom: 12px;
  padding-left: 47px;
  padding-right: 42px;
  background-color: $faux-old-lace;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shiftUp {
  margin-top: -7px;
}

.warningIcon {
  margin-right: 16px;
}

.actionRequired {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;
  text-transform: uppercase;
}

.leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.middleContent {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
}

.rightContent {
  display: flex;
  flex-direction: row;
}

.actionRequested {
  margin-right: 16px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  color: $tundora;
}

.goToDealerPortal {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 2.25px;
  text-transform: uppercase;
  color: $primary-text-bold-color;
}
