@import '../../styles/empCssLibs/variables.scss';

.Edit {
  position: relative;
  width: 18px;
  height: 18px;
  display: inline-block;
  padding-left: 12px;
  cursor: pointer;
}

.RectangleStart {
  width: 5px;
  height: 5px;
  transform: rotate(-180deg);
  background-color: $button-primary-bg-color;
  position: absolute;
  left: 7px;
  bottom: 0;
  cursor: pointer;
}

.RectangleEnd {
  position: absolute;
  width: 6px;
  height: 15.8px;
  transform: rotate(-315deg);
  border: solid 1px $primary-text-bold-color;
  background-color: $primary-bg-color;
  cursor: pointer;
}