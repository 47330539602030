@import "../../styles/empCssLibs/variables.scss";

.infoBar {
  background-color: $white;
  padding: 16px 16px 8px 16px;

  @media screen and (min-width: 1440px) {
    padding: 24px 56px 24px 56px;
  }

  .brandLogo {
    padding: 0;
  }

  .logoutContainer {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
    text-align: right;

    .dealerBox {
      padding: 2px 2px 2px 16px;
      transition: 0.2s background-color ease-in-out;

      &:hover {
        cursor: pointer;
        background-color: #f4f4f4;
        border-radius: 8px;
      }

      .menuAlign {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .adminMenu {
        text-align: right;
      }

      .dealerDetails {
        .detailsMeta {
          flex: 1 0 auto;
        }

        .detailsLink {
          flex: 0 0 auto;
        }

        a {
          font-size: 12px;
          line-height: 1;
          color: $primary-text-bold-color;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .userName {
          font-size: 12px;
          color: $primary-text-bold-color;
          text-align: right;
        }

        .dealerName,
        .dealerType,
        .dealerCommonId {
          font-size: 12px;
          color: $primary-text-bold-color;
          text-align: right;
          margin-bottom: 3px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .dealerType,
        .dealerCommonId {
          color: #4d4d4d;
        }
      }
    }
  }
}

header.globalNavBar {
  background-color: $primary-bg-color;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .navContainer {
    overflow-x: scroll;

    @media screen and (min-width: 1440px) {
      overflow-x: initial;
    }

    .navItems {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      flex: 0 0 auto;

      a {
        text-decoration: none;
        margin-right: 27px;
        padding: 0px 0px 8px 0px;
        border-bottom: 4px solid transparent;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          border-bottom: 4px solid #0068d0;

          p {
            color: #0068d0;
          }
        }

        &.active {
          border-bottom: 4px solid #0068d0;

          p {
            color: #0068d0;
          }
        }
      }

      .portalMenu {
        font-size: 13px;
        line-height: 1;
        padding: 16px;
        margin: 0;
        color: #4d4d4d;
        text-decoration: none;
        display: flex;
        align-items: center;
        border-bottom: 4px solid transparent;
        transition: border-bottom-color 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          color: #0068d0;
          border-bottom: 4px solid #0068d0;
        }

        svg {
          font-size: 0.8rem;
          margin-left: 2px;
        }
      }
    }
  }
}

.logoutFont {
  color: #4d4d4d;
  font-size: 13px;
  line-height: 1;
  margin: 0;

  &:hover {
    cursor: pointer;
    color: #0068d0;
  }
}

.dealerWrapper {
  height: 100%;
}

.logoutConfirmationFont {
  height: 42px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: 1.5px;
  text-align: -webkit-center;
}

.logoutDialogText {
  font-size: 16px;
  margin: 0;
}

.withPointer {
  cursor: pointer;
}

.btn:hover {
  background-color: transparent !important;
}

.dealerShipName {
  display: flex;
  align-items: center;
  padding: 18px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeView {
  height: 75px;
  color: $primary-text-bold-color;
  font-weight: 500;
  letter-spacing: 1.5px;
}

.dealerMenuSection {
  div {
    color: $primary-text-color;
    font-weight: 500;
    padding-left: 40px;
    padding-right: 40px;
  }

  li {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.dealerMenuSection.dealerMenuRight {
  padding-right: 40px !important;
}

.dealerMenuSection .activeDealerMenuItem {
  color: $primary-text-color;
}

.menuHR {
  margin-left: 40px;
}

li.dealerMenuItem {
  background-color: $primary-bg-color !important;
  height: 10px;
  color: $primary-text-bold-color;
}

.dealerContainer {
  height: 100%;
}

.settingsInfo {
  padding-bottom: 5px;
}

.dialogDescription,
.settings {
  white-space: pre-line;
}

.popupSection {
  padding-bottom: 16px;
}

.styledListItem {
  @extend .dialogDescription;
  padding: 5px 60px;
}

.nonStyledListItem {
  @extend .dialogDescription;
  padding: 5px 60px;
}

.languageContainer button {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 0 0 30px;
  min-width: 100px;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

.closeDialogBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.warning {
  margin: 0 5px;
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url("../../imgs/warning.svg");
}

.titleContainer {
  display: flex;
  align-items: center;
}

.oneButtonContainer {
  width: 50%;
  display: inline-flex;
  align-items: center;
}

.singleDialogActions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 60px 20px 60px;
}

.flex {
  flex-grow: 1;
}

.justifyEnd {
  justify-content: flex-end;
}
