.sortUpFilled {
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_up_filled.svg');
  display: block;
  margin: 0 3px;
  letter-spacing: 0px;
}

.sortDown {
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_down.svg');
  display: block;
  margin: 0 3px;
  letter-spacing: 0px;
}

.sortDownFilled {
  width: 15px;
  height: 10px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/sort_down_filled.svg');
  display: block;
  margin: 0 3px;
  letter-spacing: 0px;
}