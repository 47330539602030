@import "./empCssLibs/variables";
@import "~react-datetime/css/react-datetime.css";

.react-datepicker-wrapper {
  width: 100%;
}
// updated component styles
.react-datepicker.emp-datepicker {
  border-radius: 8px;
  border: 1px solid #cccccc;

  .react-datepicker__header {
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom: 1px solid #cccccc;

    &.react-datepicker__header--time {
      border-top-right-radius: 8px;
      border-top-left-radius: 0;
    }
  }

  .react-datepicker__time-container {
    border-left: 1px solid #cccccc;

    .react-datepicker__time {
      border-bottom-right-radius: 8px;
    }
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 25px;
    }

    &--selected {
      background-color: #0068d0;
      border-radius: 25px;
    }
  }
}

// previous styles
.rdtPicker {
  width: 426px;
  height: 270px;
  padding: 1px 1px 54px;
  border: solid 1px $primary-text-color;

  th.rdtNext,
  th.rdtPrev {
    vertical-align: middle;
    font-size: 25px;
    color: $primary-bg-color;
    background-color: $primary-text-bold-color;
  }

  th {
    border-bottom: 0;
  }

  th.rdtSwitch {
    width: 70%;
  }

  td.rdtMonth,
  td.rdtYear {
    height: 85px;
  }

  td {
    width: 54px;

    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    text-align: center;
    color: $primary-text-color;
    line-height: 25px;
    margin-top: 6px;
    border-radius: 3px;
  }

  td.rdtActive,
  td.rdtActive:hover {
    color: white;
    background-color: $primary-text-bold-color;
    border-radius: 3px;
  }

  td.rdtOld,
  td.rdtNew {
    background-color: $background-gray;
    border-radius: 3px;
  }

  td.rdtDisabled,
  td.rdtDisabled:hover {
    color: $dusty-gray;
    background-color: $background-gray;
    border-radius: 3px;
  }

  thead tr:first-of-type th:hover {
    background: $primary-text-bold-color;
  }

  .dow {
    width: 54px;

    font-size: 12px;
    font-weight: bold;
    color: $primary-text-bold-color;
    text-transform: uppercase;
    padding-top: 10px;
    line-height: 30px;
    letter-spacing: 1.88px;
  }

  .rdtDays {
    .rdtTimeToggle {
      vertical-align: middle;
      padding: 7px;
      background-color: $primary-text-bold-color;
      color: $primary-bg-color;
    }
  }

  .rdtSwitch {
    font-stretch: normal;
    font-style: normal;
    line-height: 3.7;
    letter-spacing: 2.5px;

    color: #ffffff;
    background-color: $primary-text-bold-color;
    border: solid 1px $primary-text-bold-color;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
  }

  .rdtCounters {
    display: flex;
    justify-content: center;
    padding-top: 15%;

    .rdtCounter {
      width: 25%;
      display: flex;
      flex-direction: column;
      padding: 0 3%;

      .rdtCount {
        padding: 15% 0;

        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: normal;
        text-align: center;
        color: #2c3135;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.35);
        background-color: $primary-bg-color;
      }

      .rdtBtn {
        font-size: 14px;
      }
    }

    .rdtCounterSeparator {
      padding: 3% 0;

      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 1.25px;
      text-align: center;
      color: $primary-text-bold-color;
    }
  }
}
