@import "./empCssLibs/variables";

/* Page Properties */
.appWrapper {
  position: relative;
  min-height: 100vh;
}

.appWrapper input::-ms-clear {
  display: none;
}

.pageWrapper {
  position: relative;
  min-height: 100%;
  min-width: 100%;
}

.topPadding {
  padding-top: 70px;
}

.pageInfoWrapper {
  display: flex;
  position: relative;
}

.pageInfo {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  margin-top: 14px;
  letter-spacing: 1px;
  color: $primary-text-color;
}

// for device up to 600px
@media only screen and (max-width: $sm) {
  .pageInfo {
    font-size: 11px;
    line-height: 1.64;
    letter-spacing: 0.5px;
  }
}

// for devices up to 900px
@media only screen and (max-width: $md) {
  .pageInfo {
    font-size: 10px;
    line-height: 1.8;
    letter-spacing: 1.5px;
  }
}

.centerAlign {
  text-align: center;
}

.confirmButton {
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.dialog,
  &.dialog:hover {
    background-color: $button-primary-bg-color;
    color: $primary-bg-color;
    width: 48%;
    height: 58px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    //font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 3px;
    text-align: center;
  }

  button {
    width: 200px;
    min-height: 48px;
    height: auto;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $button-primary-bg-color;
    color: $button-primary-text-color;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: 2.3px;
    text-align: center;
    border: none;
    cursor: pointer;

    &.quickStart {
      width: 120px;
      height: 120px;
    }
  }

  button:hover {
    background-color: $button-primary-bg-color;
  }

  button:focus {
    border: none;
    outline: none;
  }
}

.cancelledButton {
  @extend .confirmButton;

  &.dialog,
  &.dialog:hover {
    width: 48%;
    height: 58px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    background-color: $primary-bg-color;
    //font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 3px;
    text-align: center;
    color: $primary-text-bold-color;
  }
}

.disabledButtonDiv {
  @extend .confirmButton;
}

.disabledButtonDiv button {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.disabledButtonDiv button:hover {
  background-color: rgba(128, 128, 128, 0.25);
  color: rgba(0, 0, 0, 0.26);
}

.greenButtonDiv {
  @extend .confirmButton;

  button {
    background-color: $dark-green;
    background-image: linear-gradient(to bottom, $light-green, $dark-green);
  }

  button:hover {
    background-color: green;
  }
}

// text for the steps of the terms and conditions

.lineNumber {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.lineText {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.46;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

#select-dropdown:focus {
  border-bottom: none;
  background: transparent;
}

.tableLink {
  font-size: 12px;
  color: $primary-text-bold-color;
  text-decoration: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media print {
  .noPrint {
    display: none !important;
  }
}
