@font-face {
  font-family: FordF1Regular;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("./ford/F-1/FordF1-Regular.woff2") format("woff2");
}

@font-face {
  font-family: FordF1Medium;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("./ford/F-1/FordF1-Medium.woff2") format("woff2");
}

@font-face {
  font-family: FordF1Semibold;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("./ford/F-1/FordF1-Semibold.woff2") format("woff2");
}

@font-face {
  font-family: FordF1Bold;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("./ford/F-1/FordF1-Bold.woff2") format("woff2");
}
