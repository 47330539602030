@import "../../styles/empCssLibs/variables-lincoln";

.subNav {
  .container {
    background: $white;
  }

  .titleDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .welcome {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.74px;
    color: $primary-text-color;
    margin: 0 0 10px 0;
  }

  .root {
    background: linear-gradient(to bottom, $primary-bar-bg-color, $primary-bar-bg-color);
    color: $primary-bg-color;
    padding: 0 20px;

    @media screen and (min-width: 1024px) {
      padding: 0px 50px;
    }

    .flexContainer{
      height: 50px;
    }

    .blueBar {
      display: block;
      text-align: left;
      padding: 20px 0;

      @media screen and (min-width: 1024px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 25px 0;
      }

      .searchText {
        text-align: left;
        font-family: $lincoln-proxima-nova-semi-bold;
        letter-spacing: 2px;
        line-height: 1.5;
        font-size: 14px;
        font-weight: 500;
        color: $white;
        margin: 0 0 15px 0;

        @media screen and (min-width: 1024px) {
          margin-bottom: 0;
          flex: 0 0 auto;
          margin-right: 20px;
        }
      }

      form.searchWrapper {
        flex: 0 1 400px;
        position: relative;

        input.inputField {
          box-sizing: border-box;
          width: 100%;
          height: 40px;
          padding: 6px 30px 6px 10px;
          font-family: $lincoln-proxima-nova-semi-bold;
          letter-spacing: normal;
          background-color: $ivory-gray;
          color: $primary-text-color;
          text-align: left;
          margin: 0;
          z-index: 0;

          &::placeholder {
            font-family: $lincoln-proxima-nova-semi-bold;
            color: $primary-text-color;
            font-size: 14px;
            letter-spacing: normal;
          }

          &::-ms-clear {
            display: none;
          }
        }

        button.searchButton {
          position: absolute;
          top: 0;
          right: 6px;
          width: 24px;
          height: 40px;
          padding: 0;
          border: none;
          background: transparent;
          z-index: 1;

          &:focus {
            outline: none;
          }

          .searchIcon {
            padding: 0;
            margin: 0;
            cursor: pointer;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-image: url("../../imgs/search.svg");
          }

          .closeIcon {
            cursor: pointer;
            margin: 0;
            padding: 0;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-image: url("../../imgs/close_Cancel.svg");
          }
        }
      }
    }
  }
}

.dropdownDiv {
  margin: 0 30px;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dealerName {
  padding: 5px 10px;
  margin: 0;
}

.subNav .moreTab span {
  font-size: 14px;
  font-family: $lincoln-proxima-nova-semi-bold;
  font-weight: 500;
  text-transform: uppercase;
}

.subNav .moreTab {
  box-sizing: border-box;
  padding: 10px 42px 10px 24px;
  min-width: 109px;
  cursor: pointer;
}

#subNavMenuDropdown div li {
  width: 300px;
}

.subNav .root .scrollable {
  height: 67px;
}



