@import "../../styles/empCssLibs/variables";

.title {
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: #092a53;

  text-transform: uppercase;
  align-items: center;
  padding: 2% 0;
  justify-content: center;
  display: flex;
}

.warning {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  margin: 15px 0;
}

.subheader {
  font-size: 10px;
  font-stretch: normal;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  margin: 5px 0;
  color: $tundora;
}

.dealerContainer {
  width: 100%;
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid $secondary-border-color;
}

.dealerName {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $tundora;
  padding: 5px 0;
  text-align: center;
}

.message {
  color: $primary-text-bold-color;
  line-height: 1.63;
  letter-spacing: 1.5px;

  font-size: 14px;
  align-content: center;
  align-items: center;
  padding: 4% 35px 8% 35px;
}

.container {
  padding: 2% 6%;
  width: 100%;
}

button.applyBtn {
  width: 100%;
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $primary-text-bold-color;
  color: $primary-bg-color;
  line-height: 1.38;
  letter-spacing: 1.5px;

  font-size: 16px;
  font-weight: 500;
}

button.applyBtn:hover {
  background-color: $primary-text-bold-color;
}

button.cancelBtn {
  width: 100%;
  height: 60px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-color: $white;
  color: $primary-text-bold-color;
  line-height: 1.38;
  letter-spacing: 1.5px;

  font-size: 16px;
  font-weight: 500;
}

button.cancelBtn:hover {
  background-color: $white;
}

.actionButtons {
  padding: 6px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
