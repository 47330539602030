.homeLoaderWrapper {
  width: 154px;
  height: 90px;
  max-height: 90px;
  top: 40%;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  z-index: 1400;
  position: fixed;
}

.loaderImageHomeLoaderRegular {
  width: 130px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/ford-loader.svg');
  display: block;
  margin: auto;
}