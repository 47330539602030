.item-enter {
  background-color: rgba(227, 240, 227, 1);
  border-color: rgba(48, 122, 38, 1);
}

.item-enter-active {
  background-color: white;
  border-color: #9c9c9c;
  transition-property: background-color, border-color;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
}

.item-exit {
  background-color: rgba(246, 229, 231, 1);
  border-color: rgba(246, 229, 231, 1);
}

.item-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
