@import "../styles/empCssLibs/variables";

.alertDialogTitle {
  text-align: center;
  display: flex;
  justify-content: center;
}

.alertDialogText {
  font-weight: bold;
  padding-left: 7px;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2.5px;
  color: $bright-red;
}

.alertDialogDescription {
  width: 464px;
  height: 36px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding-bottom: 10px;
}

.loginBackButton {
  width: 392px;
  height: 50px;
  font-size: 16px;
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.5);
  letter-spacing: 1.5px;

  background-color: $primary-text-bold-color;
  padding: 10px 0;
  margin: 5px;
  line-height: 22px;
  font-weight: 500;
  border-radius: 0;
  border: none;
  color: white;
  text-transform: uppercase;
}

.dialogAction {
  justify-content: center;
  text-align: center;
}

.reportProblemOutlinedIcon {
  height: 32px;
  width: 32px;
  background-repeat: no-repeat;
  background-image: url("../imgs/warning.svg");
}

.alertBody {
  padding: 30px;
}

.alertHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

#alertErrorButton {
  justify-content: center;
}
