@import "../../../styles/empCssLibs/variables";

.toggleInput,
.toggleInputSmall {
  display: none;

  &:disabled ~ label {
    cursor: default;
  }
}

.toggleLabel {
  width: 90px;
  height: 20px;
  color: $primary-text-bold-color;
  padding-top: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 4px;
  text-align: center;
}

.switchLabel {
  width: 24px;
  height: 24px;
  border-radius: 38px;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.1),
    0 1px 5px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to bottom, $primary-bg-color, #f6f6f6);
  margin: 3px;
}

.toggleInput + label {
  width: 120px;
  height: 30px;
  cursor: pointer;
}

.toggleInputSmall + label {
  width: 80px;
  height: 30px;
  cursor: pointer;
}

.toggleInput:disabled + label .switchLabel {
  box-shadow: none;
  background-image: none;
}

.toggleInputSmall:disabled + label .switchLabel {
  box-shadow: none;
  background-image: none;
}

.noStyle {
  color: $warning-text-color;
  background-color: $primary-text-bold-color;
}

.labelYes {
  color: $primary-text-color;
}

label .labelYes {
  float: left;
}

.labelNo {
  color: $warning-text-color;
}

label .labelNo {
  float: right;
}

.toggleLabelSmall {
  @extend .toggleLabel;
  height: 16px;
  width: 45px;

  &.labelYes {
    padding-left: 5px;
  }
}

.pendingLabelStyle {
  color: $warning-text-color;
}

.switchToggle {
  width: 120px;
  height: 30px;
  border-radius: 40px;
  box-shadow: 0 1px 0 0 $primary-bg-color, 0 -5px 10px 2px $primary-bg-color,
    0 -5px 10px 2px $primary-bg-color, 0 10px 10px 0 rgba(0, 0, 0, 0.2),
    inset 0 2px 5px 0 rgba(0, 0, 0, 0.35), inset 0 -2px 5px 0 $primary-bg-color;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;

  &.toggleDisabled {
    box-shadow: none !important;
  }
}

.switchToggleSmall {
  @extend .switchToggle;
  height: 30px;
  width: 80px;
  margin: 0;
}
