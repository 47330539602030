@import "../../../../styles/empCssLibs/variables-lincoln.scss";

.pageContainer {
  padding: 0 25px;

  @media screen and (min-width: 1024px) {
    padding: 0 50px;
  }

  .subHeader {
    font-family: ProximaNovaRegular, sans-serif;
    font-size: 13px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    padding-top: 48px;
    color: $primary-text-color;
  }

  .gridHeader {
    padding-bottom: 8px;
    border-bottom: solid 4px $primary-text-color;

    .headerText {
      font-family: ProximaNovaBold, sans-serif;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1.5px;
      color: $primary-text-color;
    }

    .typeHeader {
      @extend .headerText;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .sorting {

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}


.gridBody {
  padding: 10px 0;
  border-bottom: solid 1px $primary-text-color;
  align-items: center;


  .colType {
    font-family: ProximaNovaRegular, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $primary-text-color;
    margin: 0;
    line-height: 1;
  }
}

