@import "../../../styles/empCssLibs/variables.scss";

.pagePadding {
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    padding: 0 56px;
  }
}

.title {
  font-size: 14px;
  color: $primary-text-color;
  margin-bottom: 16px;
}

.title1 {
  padding: 10px;

  font-size: 14px;
  color: $primary-text-color;
}

.emptyResults {
  padding-left: 6%;

  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
}

.timeFrameSubTitle {
  @extend .title;
  margin-top: 24px;
  font-size: 14px;
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
}

.dropdownDiv {
  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.modelFormControl {
  width: 100%;
}

.subTitle {
  padding: 10px 0 5px 0;
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
}

.searchWrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 40px;
}

.modelDropDown {
  width: 275px;
  padding-right: 2%;
}

.categoryDropDown {
  width: 200px;
  padding-right: 4%;
}

.yearDropDown {
  width: 200px;
  padding-right: 4%;
}

.buttonContainer {
  width: 205px;
  display: inline-flex;
  align-items: center;
  padding-top: 20px;
}

.searchContainer {
  background-color: $secondary-bar-bg-color;
}

.displayResultsContainer {
  padding-top: 3%;
  padding-bottom: 2%;
}

.displayResults {
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 2.5px;
  color: $primary-text-bold-color;

  .text {
    font-weight: bold;
    padding-left: 5px;
  }

  .download {
    float: right;
  }
}

.noteWrapper {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1.5px;
  color: $primary-text-color;
  padding-top: 10px;

  .bold {
    font-weight: 600;
  }
}

.rowHeader {
  color: $primary-text-bold-color;

  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
}

.specialRowHeader {
  color: $primary-text-bold-color;

  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  float: left;
  width: 80%;
}

.rowData {
  color: $primary-text-color;
  font-stretch: normal;
  font-style: normal;

  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
}

.tableHead th {
  border-bottom: 4px solid $primary-text-bold-color;
}

.partName {
  color: $primary-text-bold-color;
  text-align: left;
  font-weight: normal;
}

.alignSortIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.alignSortIconStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.sorting {
  margin-bottom: 2px;
}

.toolTipPadding {
  padding-right: 5px;
}

.headerAlign {
  padding-right: 1%;
}
.searchButton {
  margin-top: 34px;
}
