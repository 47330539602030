@import '../../../styles/empCssLibs/variables-lincoln.scss';

.header {
  font-family: $lincoln-proxima-nova;
  font-stretch: normal;
  color: $primary-text-color;
  font-size: 18px;
  font-weight: 200;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 2px;
  margin-top: 4%;
  text-transform: uppercase;
}

.title {
  padding: 10px 0;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: $primary-text-color;
}

.priceType {
  display: flex;
  justify-content: flex-start;
  padding-top: 22px;
}

.radio {
  height: 175px;
  width: 320px;
  background-image: linear-gradient(to bottom, $white, $white);
  margin-right: 2%;
  object-fit: contain;
  display: flex;
  cursor: pointer;
  padding: 10px;
}

.radioChecked {
  @extend .radio;
  border: solid 1px $primary-border-color;
}

.radioTitle {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  padding-top: 12px;
  color: $primary-text-color;
}

.text {
  max-width: 240px;
}

.radioText {
  height: 90px;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: $primary-text-color;
  padding-top: 10px;
}

.percentage {
  @extend .alignLeft;
  height: 31px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: $lincoln-proxima-nova;
  color: $primary-text-color;
}

.currency {
  height: 31px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  font-weight: 600;
  font-family: $lincoln-proxima-nova;
  color: $primary-text-color;
}

.alignLeft {
  text-align: left;
}

.brandHeader {
  padding: 3% 0 1% 0;
  font-family: $lincoln-proxima-nova-bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  text-align: right;
  color: $primary-text-color;
  line-height: 14px;
  border-bottom: 2px solid $secondary-border-color;
}

.brandText {
  padding: 1% 0;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: right;
  border-bottom: 2px solid $secondary-border-color;
  color: $primary-text-color;
}

.brand {
  display: flex;
  align-items: center;
}

.brandName {
  @extend .brand;
  @extend .alignLeft;
}

.textBox input {
  width: 50px;
}

.laborRateRow {
  @extend .brand;
  justify-content: flex-end;
}

.greenButton {
  border: none;
  border-radius: 0;
  padding: 3% 0 0 0;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  button {
    font-family: $lincoln-proxima-nova-semi-bold;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    width: 220px;
    height: 50px;
    border-radius: 0;
    box-shadow: none;
    background-color: $oxford-blue;
    color: $white;
    text-transform: none;
  }

  button:hover {
    background-color: $oxford-blue;
  }

  button:disabled {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    color: white;
    background-color: $aluminium;
  }
}

.saveChevron {
  display: contents;
}

.lincolnBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.overridesDialog {
  display: flex;
  margin: 41px 50px 40px 50px
}

.cancelButton {
  top: 15px;
  right: 9px;
  position: absolute;
}

.closeDialogBtn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.dialogTitle {
  font-family: $lincoln-proxima-nova-semi-bold;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 2px;
  color: $cyan-blue;
}

.dialogDescription {
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  color: $cyan-blue;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 25px;
}

button.customiseToBtn {
  height: 50px;
  width: 100%;
  border: solid 2px $cyan-blue;
  background-color: $primary-bg-color;
  color: $cyan-blue;
  font-family: $lincoln-proxima-nova-semi-bold;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 29px;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0;
}

button.brandConfirm, button.brandConfirm:hover {
  height: 50px;
  width: 100%;
  background-color: $oxford-blue;
  color: $primary-bg-color;
  font-family: $lincoln-proxima-nova-semi-bold;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  border-radius: 0;
  padding: 0;
  text-transform: none;
}

.saveIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-image: url("../../../imgs/saved.png");
}

.savedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}

.arrowSize {
  font-size: 12px;
}

.checkMark {
  margin-right: 10px;
}

.chevronOffset {
  transform: translateY(2px);
}

