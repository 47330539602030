@import '../../styles/empCssLibs/variables.scss';

.dialogTitle {
  font-size: 12px;
  line-height: 2.5;
  letter-spacing: 2px;
  background-color: $cherub-red;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 6%;
}

.warning {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/warning.svg');
}

.delete {
  height: 35px;
  width: 35px;
  background-repeat: no-repeat;
  background-image: url('../../imgs/delete.svg');
  margin-left: 30%;
  cursor: pointer;
}
button.smallCancelButton {
  width: 40%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $primary-bg-color, $primary-bg-color);
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.smallCancelButton:hover {
  cursor: pointer;
}

button.smallCancelButton p {
  margin: 0;
  letter-spacing: 1.5px;
  padding: 0 25px;
  font-size: 14px;
  color: $blue-zodiac;
  line-height: 22px;
  font-weight: 500;
}

button.continueBtn {
  width: 40%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $primary-text-bold-color, $primary-text-bold-color);
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.continueBtn:hover {
  cursor: pointer;
}

button.continueBtn p {
  margin: 0;
  padding: 0 25px;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

.buttonsDialog {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 40px;
}