@import "../../../styles/empCssLibs/variables-lincoln.scss";
@import "../../../styles/empCssLibs/variables.scss";
@import "../../../styles/common/CommonStyles.module";

.dealerName {
  font-family: $lincoln-proxima-nova;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: $cyan-blue;
  padding-bottom: 9px;
}

.dealerInfo {
  @extend .dealerName;
  font-weight: normal;
  font-size: 9px;
}

.info {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: normal;
  color: $cyan-blue;
  padding-bottom: 34px;
}

button.downloadBtn {
  height: 40px;
  width: 140px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  font-weight: bold;
  border-radius: 0;
  letter-spacing: 1.5px;

  font-size: 15px;
  cursor: pointer;
}

.table {
  border-top: 3px solid $cyan-blue;
  line-height: 1.17;
  width: 59%;
}

.tablePadding {
  padding-bottom: 40px;
}
