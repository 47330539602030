@import '../../../styles/empCssLibs/variables';
@import '../../../styles/empCssLibs/variables-lincoln.scss';

.pagePadding {
  box-sizing: border-box;
  padding: 20px 25px;

  @media screen and (min-width: 1024px) {
    padding: 20px 50px;
  }
}

.card {
  font-family: $lincoln-proxima-nova;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  border-radius: 0 !important;

  .title {
    background-color: $primary-bar-bg-color;
    text-align: center;
    color: $white;
    font-size: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .subTitle {
    background-color: $gray90;
    padding: 10px 5px;
    justify-content: space-evenly;

   p {
     font-size: 13px;
     font-weight: 500;
     letter-spacing: 1px;
     text-transform: uppercase;
     color: $primary-text-color;
     margin: 0;
   }

    span {
      padding: 0 2px 0 4px;
      font-weight: 700;
    }
  }
}

.contentOrange {
  color: $primary-bar-bg-color;
}

.contentGreen {
  color: $primary-bar-bg-color;
}

.bigTotal {
  font-size: 90px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
}

.bigDescription {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.5;
  color: $primary-text-color;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
}

.breakdown {
  background-color: $gray85;
  border-radius: 0;
  max-width: 200px;
  border: solid 1px $gray85;
  text-align: left;
  padding: 9px 4px 8px;

  .breakdownItem {
    padding: 10px 10px;
    color: $primary-text-color;
    font-size: 11px;
  }

  .breakdownNumber {
    padding: 0 5px;
    font-weight: bold;
  }
}

.cardActions {
  padding: 20px 16px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 120px;

  .button {
    font-family: $lincoln-proxima-nova-semi-bold;
    flex:0 0 90%;
    align-items: center;
    padding: 10px 20px;
    font-size: 18px;
    background-color: $primary-bar-bg-color;
    margin: 0;
    letter-spacing: 0;
    color: $white;
    border: none;

    &:hover {
      cursor: pointer;
    }

    .hiddenButton {
      @extend .button;
      visibility: hidden;
    }
  }

  .smallButton {
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    color: $primary-text-color;
    margin: 10px;
    line-height: 1;
    font-family: $lincoln-proxima-nova-semi-bold;
    border-bottom: 3px solid $primary-border-color;
  }
}

.subHeader {
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  line-height: 1.67;
  letter-spacing: 1px;
  color: $primary-text-color;
  padding: 20px 25px;

  @media screen and (min-width: 1024px) {
    padding: 20px 50px;
  }

  li.unstyledListItem {
    padding-left: 0px;
    text-indent: 0px;
    list-style: none;

    &:first-of-type {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-top: 10px;
    }
  }
}

.dashboardCardsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.searchChevron {
  display: inline-flex;
  align-items: center;
}
