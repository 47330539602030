@import "../../styles/common/CommonStyles.module";
@import "./NACustomerOrders.module";
@import "../../styles/empCssLibs/variables-lincoln.scss";

.blueBarWrapper {
  background-image: none;
  background-color: $cyan-blue;

  .blueBar {
    .searchText {
      font-family: $lincoln-proxima-nova;
      font-weight: 600;
      line-height: 1.33;
      letter-spacing: 1.33px;
    }

    form.searchWrapper {
      input.inputField {
        font-family: $lincoln-proxima-nova;
        background-color: $secondary-bg-color;
        color: $cyan-blue;
        border: 1px solid $greys-grey;
        border-radius: 0;
      }

      .searchIcon {
        background-image: url("../../imgs/lincoln-search.svg");
      }

      .closeIcon {
        background-image: url("../../imgs/lincoln-close-small.svg");
      }
    }
  }

  .iconButton {
    .archiveLink {
      font-family: $lincoln-proxima-nova;
      color: $primary-bg-color;
      letter-spacing: 1.35px;
    }
  }
}

.tabs {
  padding: 48px 24px 16px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (min-width: 1024px) {
    padding: 48px 48px 16px;
  }

  .unSelectedTab {
    font-family: $lincoln-proxima-nova-semi-bold;
    font-size: 13px;
    color: $primary-text-color;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.2s ease;
    padding: 8px 16px;
    text-align: center;
    font-weight: 500;

    &:hover {
      background: $smoky-white;
    }

    &:last-of-type {
      flex: 0 0 auto;
    }

    @media screen and (min-width: 1024px) {
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }

    &.selectedTab {
      font-weight: 500;
      border: none;
      background: none;
      border-radius: 0;
      color: $primary-text-color;
      border-bottom: 2px solid $french-rose;
      cursor: initial;
    }
  }
}

.noResultsFound {
  font-family: $lincoln-proxima-nova-italic;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: $no-results-text;
  margin: 15px 0;
}

.orderTypeDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.purchaseRequestText {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  letter-spacing: 0.86px;
  color: $cyan-blue;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
}

.tableLink {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  font-stretch: normal;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: $primary-text-color;
  letter-spacing: normal;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  text-decoration: underline;
}

.nameplate {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: $primary-text-color;
  letter-spacing: normal;
}

.specs {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #717171;
}

.depositDiv {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: $primary-text-color;
  letter-spacing: normal;
}

.detailsLink {
  font-family: $lincoln-proxima-nova;
  font-size: 13px;
  font-weight: bold;
  color: $primary-text-color;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: 1.63px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.specsWrapper {
  line-height: 1.4;
  padding: 0px;
}

.tableHead th {
  border: none;
}
