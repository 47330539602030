.timeslot-confirmation-success-enter .MuiSnackbarContent-root {
  background: #ffffff;
}

.timeslot-confirmation-error-enter .MuiSnackbarContent-root {
  background: #ffffff;
}

.timeslot-confirmation-success-enter-active .MuiSnackbarContent-root {
  background: #e5f3e6;
  transition: all 0.3ms ease-in;
}

.timeslot-confirmation-error-enter-active .MuiSnackbarContent-root {
  background: #f9e8ea;
  transition: all 0.3ms ease-in;
}

.timeslot-confirmation-success-enter-done .MuiSnackbarContent-root {
  background: #e5f3e6;
}

.timeslot-confirmation-error-enter-done .MuiSnackbarContent-root {
  background: #f9e8ea;
}

.timeslot-confirmation-success-exit .MuiSnackbarContent-root {
  background: #e5f3e6;
}

.timeslot-confirmation-error-exit .MuiSnackbarContent-root {
  background: #f9e8ea;
}

.timeslot-confirmation-success-exit-active .MuiSnackbarContent-root {
  background: #e5f3e6;
}

.timeslot-confirmation-error-exit-active .MuiSnackbarContent-root {
  background: #f9e8ea;
}

.timeslot-confirmation-success-exit-done .MuiSnackbarContent-root {
  background: #ffffff;
}

.timeslot-confirmation-error-exit-done .MuiSnackbarContent-root {
  background: #ffffff;
}
