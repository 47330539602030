@import "../../styles/empCssLibs/variables";

.pageContainer {
  .hero {
    background: $concrete;
    padding: 0 16px;
  }

  .paddedSection {
    padding: 16px;

    @media screen and (min-width: 1440px) {
      padding: 48px 48px 0 48px;
    }
  }

  .tabsContainer {
    padding: 32px 16px 0 16px;

    @media screen and (min-width: 1440px) {
      padding: 32px 48px 0 48px;
    }
  }
}
