@import "../../styles/empCssLibs/variables";

.pagePadding {
  padding: 0 16px;

  @media screen and (min-width: 1440px) {
    padding: 0 56px;
  }
}

.bottomPadding {
  padding-bottom: 20px;
}

.indent {
  padding: 0 6%;
}

.crcSearchIndent {
  padding: 0 64px;
}

.subheader {
  margin-top: 14px;
  color: $primary-text-color;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
}

.subNav {
  height: 50px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    #164069,
    $primary-text-bold-color
  );
}

.inputSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $button-primary-bg-color;

  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 3px;
}
.inputSearchWrapper button {
  border: none;
}

.inputSearchWrapper button:focus {
  outline: none;
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
}

.searchWrapper button {
  border: none;
}

.searchByLabelWrapper {
  width: 75px;
}

.searchWrapper button:focus {
  outline: none;
}

.form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.searchFormWrapper {
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 16px;
}

.searchFormHeader {
  margin-bottom: 16px;
  color: $primary-text-color;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.formSection {
  margin-right: 16px;
  flex: 1;
}

.inputField {
  margin: 10px 0 10px 10px;
  padding: 5px;
  width: 230px;
  height: 25px;
  box-shadow: inset 0 2px 5px 0 rgba(0, 0, 0, 0.35);
  background-color: #f6f6f6;
}

.crcInputField {
  height: 20px;
  box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #f6f6f6;
  padding: 10px 10px 10px 10px;
}

.emailInput {
  width: 255px;
  input {
    @extend .crcInputField;
  }
}

.nameInput {
  width: 200px;
  input {
    @extend .crcInputField;
  }
}

.zipInput {
  width: 100px;
  input {
    @extend .crcInputField;
  }
}

.noOrders {
  color: $primary-text-color;
  margin: 30px 0;
  text-align: center;
}

.searchIcon {
  width: 32px;
  height: 32px;
  background-image: url("../../imgs/search-dark-blue.svg");
  cursor: pointer;
}

.searchIcon:hover {
  cursor: pointer;
}
.searchButtonIcon {
  background: transparent;
}

.form input:focus {
  outline: none;
}

.searchButton {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  letter-spacing: 1.5px;

  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0px;
}

.searchButtonInactive {
  @extend .searchButton;
  background-image: none;
  background-color: $dusty-gray-light;
  box-shadow: none;
}

.searchLabel {
  display: flex;
  flex-direction: column;
}

.orderSearchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 13px;
}

.searchByEmailWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.orPadding {
  padding-right: 20px;
}

.orSection {
  padding: 0 16px;
  justify-content: center;

  color: $blue-zodiac;
}

.inputLabel {
  padding-bottom: 5px;
  color: $blue-zodiac;
  font-size: 12px;
}

.inputLabelInactive {
  @extend .inputLabel;
  color: $dusty-gray-light;
}

.subheaderPadding {
  padding-bottom: 20px;
}

.labelPadding {
  color: $primary-text-color;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  margin: 0px 50px;
  padding-top: 25px;
}

.andLabelPadding {
  color: $primary-text-color;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  padding: 28px 20px;
}

.formLabel {
  padding-top: 30px;
}
