@import "../../../styles/empCssLibs/variables-lincoln.scss";

.title {
  padding: 40px 0;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  height: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: $primary-text-color;
}

.percentage {
  @extend .alignLeft;
  height: 31px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-family: $lincoln-proxima-nova-semi-bold;
  color: $primary-text-color;
}

.headerPercentage {
  @extend .percentage;
  color: white;
}

.currency {
  height: 31px;
  display: flex;
  align-items: center;
  padding: 2px 6px;
  font-weight: 600;
  font-family: $lincoln-proxima-nova-semi-bold;
  color: $primary-text-color;
}

.headerCurrency {
  @extend .currency;
  color: $primary-bar-bg-color;
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.namePlateHeader {
  padding: 2% 6% 5px 6%;
  font-family: $lincoln-proxima-nova-bold;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.5px;
  text-align: right;
  color: $primary-text-color;
  line-height: 1.6;
  border-bottom: 4px solid $primary-text-color;
}

.categoryHeader {
  @extend .namePlateHeader;
  border-bottom: 5px solid $oxford-blue;
}

.categoryRow {
  padding: 0 6%;
  letter-spacing: 1.5px;
  line-height: 1.5;
  background: $secondary-bar-bg-color;
  border-bottom: 3px solid #f6f7f7;
}

.namePlateTextDiv {
  height: auto;
  font-family: $lincoln-proxima-nova;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  color: white;
  text-align: right;
  display: flex;
  align-items: center;
  background-color: $primary-bar-bg-color;
  padding: 0 6%;
  margin-top: 2px;
}

.brand {
  display: flex;
  align-items: center;
}

.brandName {
  font-size: 11px;
  font-family: $lincoln-proxima-nova-semi-bold;
  display: flex;
  justify-content: space-between;
  font-stretch: normal;
  font-style: normal;
  color: $primary-text-color;
  line-height: 1.5;
  letter-spacing: 1px;
  border-bottom: solid 2px #f6f7f7;
}

.brandValue {
  font-size: 11px;
  font-family: $lincoln-proxima-nova-semi-bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-stretch: normal;
  font-style: normal;
  color: $primary-text-color;
}

.typeHeader {
  @extend .alignLeft;
  @extend .alignSortIcon;
}

.brandText {
  padding-right: 75px;
}

.textBox {
  height: auto;
}

.textBox input {
  width: 50px;
  height: 10px;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 7px;
  box-shadow: inset 2px 2px 2px 0 rgba(0, 0, 0, 0.27);
  border: solid 1px $secondary-border-color;
  background-color: $primary-bg-color;
  margin: 5px 0;
}

.textBox p {
  margin-bottom: 5px;
}

.laborRateRow {
  @extend .brand;
  align-items: center;
  justify-content: center;
}

.greenButton {
  border: none;
  border-radius: 0;
  padding: 3% 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    font-family: $lincoln-proxima-nova-semi-bold;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    width: 220px;
    height: 50px;
    border-radius: 0;
    box-shadow: none;
    background-color: $oxford-blue;
    color: $white;
    text-transform: none;
  }

  button:disabled {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
    color: white;
    background-color: $aluminium;
  }
}

.overridesDialog {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 6%;
  text-align: center;
}

.cancelButton {
  display: flex;
  justify-content: flex-end;
  padding: 23px 23px 0 0;
}

button.brandConfirm {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-text-color,
    $primary-text-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.brandConfirm:hover {
  cursor: pointer;
}

button.brandConfirm p {
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  line-height: 22px;
  font-weight: 500;
  text-transform: uppercase;
}

button.customiseToBtn {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-bg-color,
    $primary-bg-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.customiseToBtn:hover {
  cursor: pointer;
}

button.customiseToBtn p {
  margin: 0;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: $primary-text-color;
  line-height: 22px;
  font-weight: 500;
  font-family: $lincoln-proxima-nova-semi-bold;
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
}

.dropdownsWrapper {
  display: flex;
}

.dropdownDiv {
  width: 250px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.modelFormControl {
  width: 100px;
}

.subTitle {
  @extend .title;
  padding: 10px 0px;
  font-size: 14px;
  text-transform: lowercase;
}

.subTitle::first-letter {
  text-transform: uppercase;
}

.headerBlueRow {
  background-color: $primary-text-color;
}

.expansionBar {
  width: 100%;
  font-size: 11px;
  font-family: $lincoln-proxima-nova-bold;
  letter-spacing: 1.5px;
  padding: 0 6%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $primary-text-color;
  text-transform: uppercase;
}

.cancel {
  padding: 10px 5px 0 10px;
  background: transparent;
  justify-content: flex-end;
  display: flex;
}

.cancel button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.buttonsDialog {
  text-align: center;
  padding-top: 2%;
  padding-bottom: 40px;
}

button.applyButton {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-text-color,
    $primary-text-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.applyButton:hover {
  cursor: pointer;
}

button.applyButton p {
  margin: 0;
  padding: 0 25px;
  letter-spacing: 1.5px;
  font-size: 14px;
  color: white;
  line-height: 22px;
  font-weight: 500;
  text-transform: none;
  font-family: $lincoln-proxima-nova-semi-bold;
}

button.cancelButtonDialog {
  width: 60%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    to bottom,
    $primary-bg-color,
    $primary-bg-color
  );
  margin: 5px;
  border-radius: 0;
  padding: 12px 0;
  border: none;
}

button.cancelButtonDialog:hover {
  cursor: pointer;
}

button.cancelButtonDialog p {
  margin: 0;
  letter-spacing: 1.5px;
  padding: 0 25px;
  font-size: 14px;
  color: $primary-text-color;
  line-height: 22px;
  font-weight: 500;
  font-family: $lincoln-proxima-nova-semi-bold;
}

.notFound {
  padding-left: 6%;
  padding-top: 1%;
  text-align: left;
}

.label {
  padding-top: 6px;
}

.alignSortIcon {
  display: flex;
  align-items: flex-end;
  //justify-content: flex-start;
}

.markupTypeLabel {
  @extend .label;
  @extend .alignSortIcon;
  justify-content: center;
}

.overridesApplied {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 30px 0;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.17;
  letter-spacing: 2.75px;
  font-family: $lincoln-proxima-nova;
  text-transform: uppercase;
}

.overridesApplied span {
  color: $secondary-green;
  margin-right: 10px;
}

.sortingIcon {
  margin-bottom: 2px;
}
