@import "../../styles/empCssLibs/variables";
@import "../../styles/empCssLibs/variables.scss";

.searchIndent {
  padding: 10px 16px;
  box-sizing: border-box;

  @media screen and (min-width: 1024px) {
    padding: 10px 56px;
  }
}

.subheader {
  color: $primary-text-color;
  font-size: 14px;
  margin-top: 0px;
}

.error {
  @extend .subheader;
  color: red;
}

.boldHeader {
  font-size: 32px;

  color: $primary-text-bold-color;
  margin-bottom: 8px;
}

.buttonContainer {
  width: 128px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
}

.textBox input[type="text"] {
  width: 250px;
  line-height: 20px;
}

.textBox fieldset {
  border: none;
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 25px;
  padding-bottom: 20px;
}

.errorContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

button.actionBtn {
  width: 90%;
  height: 44px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  border-radius: 0;
  letter-spacing: 1.5px;

  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 16px 0px 32px 0;
}

.labelPadding {
  color: $primary-text-color;
  font-size: 16px;
  margin: 0px 32px;
}

.radioLabelItem {
  align-items: center;
  text-align: left;
}

.validatorForm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.iBallOffset {
  transform: translate(3px, -8px);
  position: absolute !important;
}
