@import '../../../styles/empCssLibs/variables-lincoln.scss';

.title, .timeFrameSubTitle {
  margin-left: 50px;
  margin-bottom: 30px;
  font-family: $lincoln-proxima-nova;
  font-size: 16px;
  height: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: $primary-text-color;
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;
}

.dropdownDiv {

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.modelFormControl {
  width: 100px;
}

.subTitle {
  margin-bottom: 10px;
  letter-spacing: 1.5px;
  font-size: 12px;
  color: $primary-text-color;
  line-height: 22px;
  font-weight: 500;
  font-family: $lincoln-proxima-nova-semi-bold;
}

.searchWrapper {
  display: flex;
  padding-bottom: 50px;
}

.modelDropDown {
  width: 275px;
  padding-right: 2%;
}

.categoryDropDown {
  width: 200px;
  padding-right: 4%;
}

.yearDropDown {
  width: 200px;
  padding-right: 4%;
}

.buttonContainer {
  width: 205px;
  display: inline-flex;
  align-items: center;
  padding-top: 32px;
}

.searchContainer {
  margin-top: 40px;
}

.searchChevron {
  display: contents;
}

.displayResultsContainer {
  padding-top: 3%;
  padding-bottom: 2%;
}

.displayResults {
  text-transform: uppercase;
  font-family: $lincoln-proxima-nova;
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 1.5px;
  color: $primary-text-color;

  .text {
    font-family: $lincoln-proxima-nova-bold;
    font-weight: bold;
    padding-left: 5px;
  }

  .download {
    float: right;
  }
}

.noteWrapper {
  font-family: $lincoln-proxima-nova;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: $primary-text-color;
  padding-top: 10px;

  .bold {
    font-family: $lincoln-proxima-nova;
    font-weight: 600;
  }
}

.rowHeader {
  color: $primary-text-color;
  font-family: $lincoln-proxima-nova-bold;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
}

.specialRowHeader {
  color: $primary-text-color;
  font-family: $lincoln-proxima-nova-bold;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.5px;
  float: left;
  height: 80%;
}

.rowData {
  color: $primary-text-color;
  font-stretch: normal;
  font-style: normal;
  font-family: $lincoln-proxima-nova;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: right;
}

.tableHead th {
  border-bottom: 4px solid $primary-text-color;
}

.tableRow {
  background: $secondary-bar-bg-color;
}

.partName {
  color: $primary-text-color;
  text-align: left;
  font-weight: normal;
}

.alignSortIconStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.alignSortIcon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.sorting {
  margin-bottom: 2px;
}

.searchButton{
  margin-top: 34px;
}