@import "../../styles/empCssLibs/variables";

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.brandDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandLogo {
  color: $primary-text-bold-color;
  height: 75px;
  margin-top: 16vh;
}

.deniedInfo {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
  text-align: left;
  padding: 0 15%;
}

.deniedTitle {
  font-size: 24px;
  color: $primary-text-bold-color;
  line-height: 1.75;
  letter-spacing: 1px;
  text-align: center;
  margin: 20px 0;
}

.awaitingManagement {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.59px;
  margin: 15px 0;
  color: $primary-text-bold-color;
}

.inOrderToAccess {
  width: 727px;
  height: 50px;

  font-size: 12px;
  line-height: 1.75;
  letter-spacing: 0.92px;
  color: $primary-text-color;
  margin: 15px 0;
}

.toCompleteTheOnboarding {
  width: 697px;
  height: 35px;

  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.92px;
  color: $primary-text-color;
}

.number {
  margin: 10px 0;
}

.lineNumber {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.86px;
  color: $primary-text-bold-color;
}

.lineText {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.46;
  letter-spacing: 1px;
  color: $primary-text-bold-color;
}

.normalFontWeight {
  font-weight: normal;
}

.deniedContainer {
  width: 65%;
  margin: auto;
}

.subtext {
  width: 50%;
  margin: auto;
}

.redirectUrl {
  text-decoration: underline;
  cursor: pointer;
  padding-left: 457px;
}
