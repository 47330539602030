@import "../../styles/empCssLibs/variables";
@import "../../styles/empCssLibs/variables.scss";

.pagePadding {
  padding: 0 25px;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
  }
}

.topDivider {
  border-bottom: 4px solid $blue-zodiac;
  margin-bottom: 55px;
}
.dealerDetails {
  padding-top: 40px;
}

.dateDivider {
  border-bottom: 1px solid $dusty-gray;
  width: 15px;
  margin: 0 10px;
}

.bottomDivider {
  border-bottom: 1px solid $dusty-gray;
  width: 100%;
  padding-top: 42px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 39px;
  padding-bottom: 58px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  padding-right: 30px;
  color: $blue-zodiac;
}

.subTitle {
  font-size: 14px;
  letter-spacing: 1.94px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  padding-right: 10px;
  padding-bottom: 10px;
  color: $blue-zodiac;
}

.locationLabel {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: bolder;
  color: $tundora;
}

.locationLabelSub {
  @extend .locationLabel;
  padding-left: 10px;
  font-weight: normal;
}

.reportToIncludeLabel {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: normal;
  color: $tundora;
  padding-right: 30px;
}

.checkBoxDiv {
  display: flex;
  align-items: center;
}

.reportToIncludeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.reportErrorLabel {
  @extend .locationLabel;
  padding-top: 10px;
  font-weight: normal;
}

.dealerName {
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: $blue-zodiac;
  padding-bottom: 9px;
}

.dealerInfo {
  @extend .dealerName;
  font-weight: normal;
  font-size: 9px;
}

.info {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: normal;
  color: $tundora;
  padding-top: 25px;
  padding-bottom: 34px;
  width: 80%;
  line-height: 21px;
}

.dateRange {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: bolder;
  color: $tundora;
}

.dateRangeSub {
  @extend .dateRange;
  font-weight: normal;
  padding-left: 9px;
}
.resetLabel {
  font-size: 13px;
  letter-spacing: 2px;

  font-weight: bold;
  padding-left: 5px;
  padding-top: 2px;
  padding-right: 38px;
  color: $blue-zodiac;
  cursor: pointer;
}

.subTitleBottom {
  @extend .subTitle;
  padding-top: 48px;
  padding-bottom: 32px;
}

.dropDown {
  display: flex;
  flex-direction: column;
}

.dropDownLabel {
  padding-bottom: 5px;
  color: $blue-zodiac;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: bold;
  text-transform: uppercase;
}

.dropDownLabelDisabled {
  @extend .dropDownLabel;
  color: grey;
}

.toLabel {
  color: $blue-zodiac;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: bold;
  padding-left: 10px;
}

.fromLabel {
  color: $blue-zodiac;
  font-size: 11px;
  letter-spacing: 2px;
  font-weight: bold;
}

.typeAndRegionWrapper {
  padding-top: 27px;
  padding-bottom: 60px;
}

.searchByLink {
  padding-top: 10px;
  text-decoration: underline;
  font-weight: normal;
  cursor: pointer;
  color: $blue-zodiac;
}

.searchInput input[type="text"] {
  width: 270px;
}

input {
  width: 130px;
  color: $primary-text-bold-color;
  height: 22px;
  border: solid 1px $secondary-text-color;
  font-size: 14px;
  text-align: left;

  line-height: 1.43;
  padding: 1px 10px;
  border-radius: 3px;
  letter-spacing: 1px;
  background-color: $white;
}

.error {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: red;
}

.dateRangeWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 13px;
}

.dropDownDateWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.generateReport {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 84px;
  padding-top: 16px;
}

.reset {
  transform: rotate(-75deg);
  cursor: pointer;
}

.downloadButtonContainer {
  width: 158px;
  float: right;
  margin-right: 27px;
}

button.actionBtn {
  width: 90%;
  height: 50px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, $blue-zodiac, $blue-zodiac);
  color: $primary-bg-color;
  line-height: 1.63;
  font-weight: bold;
  border-radius: 0;
  letter-spacing: 1.5px;

  font-size: 15px;
  cursor: pointer;
}

button.actionBtn:disabled {
  @extend .actionBtn;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  opacity: 0.3;
}

.datePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.calImgFrom {
  position: absolute;
  bottom: 8px;
  right: 16px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  cursor: pointer;
}

.calImgTo {
  position: absolute;
  bottom: 8px;
  right: 7px;
  width: 24px;
  height: 24px;
  pointer-events: none;
  cursor: pointer;
}

.toFromDatePickerWrapper {
  padding-bottom: 19px;
}

.datePickerInputIcon {
  position: relative;
  svg {
    position: absolute;
    bottom: 8px;
    right: 7px;
    pointer-events: none;
    cursor: pointer;
  }
}

.datePickerInputIcon input[type="text"] {
  height: 24px;
  width: 135px;
}

.orPadding {
  padding-right: 20px;
}

.modelFormControl {
  width: 100%;
}

.dropDownsWrapper {
  display: flex;
}

.dropdownAutoDate {
  width: 220px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dropdownDiv {
  width: 250px;
  padding-right: 2%;

  .dropdown:before {
    border-bottom: none;
    transition: none;
  }

  .dropdown:after {
    border-bottom: none;
    transition: none;
  }
}

.dropDownText {
  padding: 5px 10px;
  margin: 0;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 1.5px;
  color: $tundora;
}

.dropDownTextSmall {
  @extend .dropDownText;
  font-size: 12px;
}

.blueBar {
  height: 50px;
  background: linear-gradient(to bottom, #164069, $primary-text-bold-color);
  color: $primary-bg-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 6%;
  padding-right: 6%;
}

.blueBar .flexContainer {
  height: 50px;
}
